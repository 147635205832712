import { Divider, Navbar } from "@mantine/core";
import { FC } from "react";
import { NavItem } from "../../../../Components/NavItem";
import {
  IconBallFootball,
  IconBrandInstagram,
  IconBrandYoutube,
  IconCalendarClock,
  IconExternalLink,
  IconShirtSport,
  IconSoccerField,
  IconTable,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

type NavigationProps = {
  navbarOpened: boolean;
  closeNavbar: () => void;
};

export const Navigation: FC<NavigationProps> = (props) => {
  const { navbarOpened, closeNavbar } = props;

  const navigate = useNavigate();

  return (
    <Navbar
      p="md"
      hiddenBreakpoint="sm"
      hidden={!navbarOpened}
      width={{ sm: 250 }}
    >
      <NavItem
        onClick={() => {
          navigate("/");
          closeNavbar();
        }}
        color="blue"
        icon={<IconSoccerField size={16} />}
        label="Startseite"
      />
      <NavItem
        onClick={() => {
          navigate("games");
          closeNavbar();
        }}
        color="blue"
        icon={<IconCalendarClock size={16} />}
        label="Spiele"
      />
      <NavItem
        onClick={() => {
          navigate("teams");
          closeNavbar();
        }}
        color="blue"
        icon={<IconShirtSport size={16} />}
        label="Mannschaften"
      />
      <NavItem
        onClick={() => {
          navigate("topscorer");
          closeNavbar();
        }}
        color="blue"
        icon={<IconBallFootball size={16} />}
        label="Topscorer"
      />
      <NavItem
        onClick={() => {
          navigate("leaderboard");
          closeNavbar();
        }}
        color="blue"
        icon={<IconTable size={16} />}
        label="Vorrundentabelle"
      />
      <Divider my="sm" />
      <NavItem
        onClick={() => {
          window.open("https://www.freewaycup.de", "_blank");
        }}
        color="blue"
        icon={<IconExternalLink size={16} />}
        label="Zur Website"
      />
      <NavItem
        onClick={() => {
          window.open("https://magazin.freewaycup.de", "_blank");
        }}
        color="blue"
        icon={<IconExternalLink size={16} />}
        label="Turnier-Magazin"
      />
      <NavItem
        onClick={() => {
          window.open(
            "https://www.instagram.com/freewaycup_official/",
            "_blank",
          );
        }}
        color="blue"
        icon={<IconBrandInstagram size={16} />}
        label="Instagram"
      />
      <NavItem
        onClick={() => {
          window.open("https://www.youtube.com/user/FreewayCup", "_blank");
        }}
        color="blue"
        icon={<IconBrandYoutube size={16} />}
        label="Youtube"
      />
    </Navbar>
  );
};
