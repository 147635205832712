import { FC, useState } from "react";
import { Button, Loader } from "@mantine/core";
import { TeamPlayerModal } from "./TeamPlayerModal";
import { gameScoreGoal } from "../../../../openapi/api-client";
import { useMutation } from "@tanstack/react-query";
import { v4 as uuid } from "uuid";
import { TeamName } from "../../../../Components/TeamName";
import { useGame } from "../../../../hooks/useGame.ts";

type GoalButtonProps = {
  teamId: string;
  disabled: boolean;
  time: number;
  gameId: string;
  goals: number;
};

export const GoalButton: FC<GoalButtonProps> = (props) => {
  const { teamId, disabled, time, gameId, goals } = props;
  const [modalOpened, setModalOpened] = useState(false);

  const [scorer, setScorer] = useState<string | undefined>();
  const [goalScoredAt, setGoalScoredAt] = useState<number | undefined>();
  const [isOwnGoal, setIsOwnGoal] = useState<boolean>(false);

  const scoreGoalMutation = useMutation({ mutationFn: gameScoreGoal });

  const game = useGame(gameId);

  if (
    !game.isSuccess ||
    !game.data.actualTeams.homeTeamId ||
    !game.data.actualTeams.guestTeamId
  ) {
    return <Loader />;
  }

  const homeTeam = game.data.actualTeams.homeTeamId == teamId;
  const otherTeamId: string = homeTeam
    ? game.data.actualTeams.guestTeamId
    : game.data.actualTeams.homeTeamId;

  const onClick = async (playerId?: string) => {
    if (scorer) {
      if (goalScoredAt == undefined) {
        throw new Error("time not set");
      }

      await scoreGoalMutation.mutateAsync({
        id: gameId,
        time: goalScoredAt,
        goalId: uuid(),
        playerId: scorer,
        assistPlayerId: playerId,
        homeTeam,
        isOwnGoal: isOwnGoal,
      });

      setModalOpened(false);
      setScorer(undefined);
      setGoalScoredAt(undefined);
      setIsOwnGoal(false);
      return;
    }

    setScorer(playerId);
  };

  return (
    <>
      <TeamPlayerModal
        teamId={scorer ? teamId : isOwnGoal ? otherTeamId : teamId}
        opened={modalOpened}
        close={() => {
          setModalOpened(false);
          setGoalScoredAt(undefined);
          setScorer(undefined);
          setIsOwnGoal(false);
        }}
        onClick={onClick}
        showEmptyPlayer={scorer ? true : !isOwnGoal}
        emptyPlayerText={
          scorer ? (
            <>
              keine
              <br />
              Vorlage
            </>
          ) : (
            <>Eigentor</>
          )
        }
        emptyPlayerAction={scorer ? undefined : () => setIsOwnGoal(true)}
        buttonColor={scorer ? "green" : "blue"}
        disabledPlayerIds={scorer ? [scorer] : undefined}
        allDisabled={scoreGoalMutation.isPending}
        headline={
          <>
            {scorer ? <>Vorlage</> : <>Tor</>} für <TeamName teamId={teamId} />
          </>
        }
      />
      <Button
        size="xl"
        fullWidth
        disabled={disabled}
        onClick={() => {
          setGoalScoredAt(time);
          setModalOpened(true);
        }}
        styles={() => ({
          root: { height: 150, fontSize: 60 },
        })}
      >
        {goals}
      </Button>
    </>
  );
};
