import { FC } from "react";

type TimeProps = {
  time: number;
  leadingSpace?: boolean;
};

export const Time: FC<TimeProps> = (props) => {
  const { time, leadingSpace } = props;
  return (
    <>
      {leadingSpace && time < 10 * 60 && <>0</>}
      {Math.floor(time / 60)}:{time % 60 < 10 ? <>0{time % 60}</> : time % 60}
    </>
  );
};
