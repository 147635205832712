import { socket } from "../socket.ts";
import { BaseCommand } from "../commandTypes.ts";
import { useEffect } from "react";

export const useCommand = <T extends BaseCommand>(
  c: string,
  f: (command: T) => void,
) => {
  useEffect(() => {
    socket.on("command", (command: T) => {
      console.log("command", command);
      if (command.command == c) {
        f(command);
      }
    });

    return () => {
      socket.removeListener("command");
    };
  }, []);
};
