import { FC, useContext, useEffect, useState } from "react";
import { Loader, Text } from "@mantine/core";
import { DateTime } from "luxon";
import { IconCircleCheck } from "@tabler/icons-react";
import { Time } from "../../../Components/Time";
import { useGameTime } from "../../../hooks/useGameTime";
import { CompetitionContext } from "../../../CompetitionContext.tsx";
import { useCurrentGame } from "../../../hooks/useCurrentGame.ts";

export const Countdown: FC = () => {
  const competitionId = useContext(CompetitionContext);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const currentGame = useCurrentGame(competitionId);
  const time = useGameTime(currentGame.data?.id);

  if (!currentGame.isSuccess) {
    return <Loader />;
  }

  return (
    <div
      style={{
        backgroundColor: currentGame.data.livestreamRegieReady
          ? "green"
          : "red",
        height: 1080,
        textAlign: "center",
        fontSize: 600,
        color: "white",
      }}
    >
      {currentGame.data.livestreamRegieReady ? (
        <>
          {currentGame.data.started ? (
            <>
              <Time time={time} />
            </>
          ) : (
            <>
              <IconCircleCheck size={1000} color="white" stroke={1} />
            </>
          )}
        </>
      ) : (
        <Text>
          {DateTime.fromISO(currentGame.data.postponedDateTime)
            .diff(DateTime.fromJSDate(currentTime))
            .toFormat("mm:ss")}
        </Text>
      )}
    </div>
  );
};
