import { Page } from "../../../Components/Page";
import { FC, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { listCompetitions } from "../../../openapi/api-client";
import { Alert, Grid, Loader, Select } from "@mantine/core";
import { TeamLineup } from "./Compenents/TeamLineup";
import { useDebouncedCurrentGame } from "../../../hooks/useDebouncedCurrentGame";
import { IconHeadset } from "@tabler/icons-react";
import { useShowCommentatorNotification } from "../../../hooks/useCommentatorNotification";
import { CompetitionCommand, sendCommand } from "../../../commandTypes.ts";
import { useCommand } from "../../../hooks/useCommand.ts";

export const Commentator: FC = () => {
  const [competitionId, setCompetitionId] = useState<string>(
    import.meta.env.VITE_APP_COMPETITION_ID,
  );
  useCommand<CompetitionCommand>("competition", (c) => {
    setCompetitionId(c.competitionId);
  });

  const competitionData = useQuery({
    queryKey: ["competitions"],
    queryFn: async () => {
      const response = await listCompetitions({});
      return response.data;
    },
  });

  const showNotification = useShowCommentatorNotification();

  const currentGameData = useDebouncedCurrentGame(competitionId, 10 * 1000);

  if (!competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title="Kommentator">
      {showNotification && (
        <Alert
          variant="filled"
          icon={<IconHeadset size={16} />}
          title="Bitte bei der Regie melden"
          color="red"
          onClose={() => sendCommand({ command: "commentator.off" })}
          withCloseButton
        >
          Bitte das Headset aufsetzen.
        </Alert>
      )}

      <Select
        my="md"
        label="Turnier"
        value={competitionId}
        onChange={(value) => {
          if (!value) {
            return;
          }
          setCompetitionId(value ?? undefined);
        }}
        data={competitionData.data.competitions.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
      />

      {currentGameData.isSuccess &&
        currentGameData.data.actualTeams.homeTeamId &&
        currentGameData.data.actualTeams.guestTeamId && (
          <Grid>
            <Grid.Col span={6}>
              <TeamLineup
                teamId={currentGameData.data.actualTeams.homeTeamId}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TeamLineup
                teamId={currentGameData.data.actualTeams.guestTeamId}
              />
            </Grid.Col>
          </Grid>
        )}
    </Page>
  );
};
