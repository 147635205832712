import { FC } from "react";
import { Stack, Image } from "@mantine/core";
import { Stream } from "../Widgets/Stream";
import { UpcomingGames } from "../Widgets/UpcomingGames";
import { Topscorer } from "../Widgets/Topscorer";
import { LastGames } from "../Widgets/LastGames.tsx";

export const Homepage: FC<{ isDesktop?: boolean }> = (props) => {
  const { isDesktop } = props;

  return (
    <Stack spacing="xl">
      {!isDesktop && (
        <>
          <Stream />
          <Image
            alt=""
            src="https://app-assets.freewaycup.de/sponsorads/anzeige-marktkauf.jpg"
          />
        </>
      )}
      <LastGames />
      <UpcomingGames />
      <Topscorer number={3} />
    </Stack>
  );
};
