import { FC, useContext } from "react";
import { Grid, Title, Stack } from "@mantine/core";
import { TeamLogo } from "../../../../Components/TeamLogo";
import { useNavigate } from "react-router-dom";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { useCompetition } from "../../../../hooks/useCompetition.ts";

export const Teams: FC = () => {
  const navigate = useNavigate();
  const competitionId = useContext(CompetitionContext);
  const competitionData = useCompetition(competitionId);

  return (
    <Stack spacing="xl">
      <Title order={2}>Mannschaften</Title>
      {competitionData.isSuccess ? (
        <Grid justify="center">
          {competitionData.data.teamIds.map((t) => (
            <Grid.Col key={t} span={3} h={116} onClick={() => navigate(t)}>
              <TeamLogo teamId={t} height={100} />
            </Grid.Col>
          ))}
        </Grid>
      ) : (
        <CenteredLoader />
      )}
    </Stack>
  );
};
