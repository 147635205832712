import { FC } from "react";
import { usePlayer } from "../hooks/usePlayer";
import { TextSkeleton } from "./TextSkeleton";

type PlayerNameProps = {
  playerId: string;
  firstname?: boolean;
};

export const PlayerName: FC<PlayerNameProps> = (props) => {
  const { playerId, firstname } = props;
  const player = usePlayer(playerId);

  if (!player.isSuccess) {
    return firstname ? (
      <>
        <TextSkeleton>Vorname Nachname</TextSkeleton>
      </>
    ) : (
      <TextSkeleton>Nachname</TextSkeleton>
    );
  }

  return (
    <>
      {player.data.lastName}
      {firstname && <>, {player.data.firstName}</>}
    </>
  );
};
