import React, { CSSProperties } from "react";
import { TimePenaltyCountdown } from "../../../Game/Components/TimePenaltyCountdown.tsx";
import { Time } from "../../../../../Components/Time.tsx";
import { useGameTime } from "../../../../../hooks/useGameTime.ts";
import { useGame } from "../../../../../hooks/useGame.ts";

type LedwallTimeProps = {
  gameId: string;
};

export const LedwallTime: React.FC<LedwallTimeProps> = (props) => {
  const { gameId } = props;

  const game = useGame(gameId);
  const time = useGameTime(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId;
  const guestTeamId = game.data?.actualTeams.guestTeamId;

  const timePenaltyStyle: CSSProperties = {
    width: 225,
    fontSize: 30,
    lineHeight: "40px",
  };

  return (
    <div
      style={{
        width: 720,
        height: 144,
        display: "flex",
        alignItems: "center",
        paddingLeft: 25,
        paddingRight: 25,
      }}
    >
      {game.isSuccess && (
        <>
          <div style={{ ...timePenaltyStyle }}>
            {game.data.timePenalties
              .filter((tp) => tp.teamId == homeTeamId)
              .filter((tp) => tp.time - tp.duration < time)
              .filter((tp) => !tp.finishedByGoalId)
              .slice(0, 2)
              .map((tp) => (
                <div key={tp.timePenaltyId}>
                  <TimePenaltyCountdown time={time} timePenalty={tp} />
                </div>
              ))}
          </div>
          <div
            style={{
              color: game.data?.clockRunning ? "lime" : "red",
              height: 144,
              fontSize: 90,
              fontWeight: 700,
              lineHeight: "144px",
            }}
          >
            <Time leadingSpace time={time} />
          </div>
          <div style={{ ...timePenaltyStyle, textAlign: "right" }}>
            {game.data.timePenalties
              .filter((tp) => tp.teamId == guestTeamId)
              .filter((tp) => tp.time - tp.duration < time)
              .filter((tp) => !tp.finishedByGoalId)
              .slice(0, 2)
              .map((tp) => (
                <div key={tp.timePenaltyId}>
                  <TimePenaltyCountdown time={time} timePenalty={tp} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
