import { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { listGamesByCompetition } from "../../../../openapi/api-client";
import { Button, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { GameList } from "./GameList";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";

export const LastGames: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const navigate = useNavigate();

  const games = useQuery({
    queryKey: ["games", { competitionId }],
    queryFn: async () => {
      const response = await listGamesByCompetition({
        competitionId,
      });
      return response.data;
    },
  });

  if (!games.isSuccess) {
    return <CenteredLoader />;
  }

  const lastGames = games.data?.games.filter((g) => g.result).slice(-3);

  return lastGames.length > 0 ? (
    <div>
      <Title order={2}>Die letzten Spiele</Title>
      <GameList games={lastGames} />
      <Button mt="lg" onClick={() => navigate("games")}>
        Alle Spiele
      </Button>
    </div>
  ) : (
    <></>
  );
};
