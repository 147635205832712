import { getCompetition } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useCompetition = (competitionId?: string) => {
  return useQuery({
    queryKey: ["competition", { competitionId }],
    queryFn: async () => {
      if (!competitionId) {
        throw new Error("competition id not set");
      }

      const response = await getCompetition({
        competitionId,
      });
      return response.data;
    },
    enabled: !!competitionId,
  });
};
