import { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getTopscorer } from "../../../../openapi/api-client";
import {
  Button,
  Table,
  Title,
  Image,
  Text,
  Box,
  Skeleton,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { PlayerName } from "../../../../Components/PlayerName";
import { PlayerNumber } from "../../../../Components/PlayerNumber";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { usePlayer } from "../../../../hooks/usePlayer.ts";
import { TeamLogo } from "../../../../Components/TeamLogo.tsx";
import { IconShirtSport } from "@tabler/icons-react";

type TopscorerProps = {
  number?: number;
};

export const Topscorer: FC<TopscorerProps> = (props) => {
  const { number } = props;

  const competitionId = useContext(CompetitionContext);
  const navigate = useNavigate();

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  const topscorer = topscorerData.data?.topscorer.slice(0, number);

  return (
    <div>
      <Title order={2}>Unsere Topscorer</Title>

      <Image
        my="lg"
        src="https://app-assets.freewaycup.de/sponsorads/anzeige-swierzy.svg"
        alt=""
      />

      {topscorer ? (
        <Table horizontalSpacing="xs" style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "right", width: "1%" }}>#</th>
              <th>Name</th>
              <th style={{ width: "1%" }}></th>
              <th
                style={{
                  width: "1%",
                  verticalAlign: "middle",
                }}
              >
                <IconShirtSport height={16} />
              </th>
              <th style={{ textAlign: "center", width: "1%" }}>A</th>
              <th style={{ textAlign: "center", width: "1%" }}>T</th>
              <th style={{ textAlign: "center", width: "1%" }}>S</th>
            </tr>
          </thead>
          <tbody>
            {topscorer.map((t, index) => (
              <PlayerRow
                key={t.playerId}
                playerId={t.playerId}
                goals={t.goals}
                assists={t.assists}
                position={index + 1}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <CenteredLoader />
      )}
      {number != undefined && (
        <Button mt="lg" onClick={() => navigate("topscorer")}>
          Alle Scorer
        </Button>
      )}
    </div>
  );
};

const PlayerRow: FC<{
  playerId: string;
  goals: number;
  assists: number;
  position: number;
}> = (props) => {
  const { playerId, goals, assists, position } = props;

  const playerData = usePlayer(playerId);

  return (
    <tr>
      <td style={{ textAlign: "right" }}>{position}.</td>
      <td style={{ textAlign: "left" }}>
        <Text lineClamp={1} style={{ wordBreak: "break-all" }}>
          <PlayerName firstname playerId={playerId} />
        </Text>
      </td>
      <td>
        <Box w={20} h={20}>
          {playerData.isSuccess ? (
            <TeamLogo teamId={playerData.data.teamId} height={20} />
          ) : (
            <Skeleton h={20} w={20} />
          )}
        </Box>
      </td>
      <td>
        <PlayerNumber playerId={playerId} />
      </td>
      <td>{assists}</td>
      <td>{goals}</td>
      <td>{assists + goals}</td>
    </tr>
  );
};
