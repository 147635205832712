import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { socket } from "./socket";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MantineProvider } from "@mantine/core";
import { Settings } from "luxon";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { DeviceTags } from "./DeviceTagContext.tsx";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://1e93fe824eab40a2b04a9634d406d517@glitchtip.patrickhilker.de/1",
  environment: process.env.NODE_ENV,
  enableTracing: true,
  tracesSampleRate: 0.3,
  beforeSend: (event) => {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [new Sentry.BrowserTracing()],
});

Settings.defaultLocale = "de";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: "always",
      refetchInterval: false,
      refetchIntervalInBackground: false,
    },
  },
});

socket.on("event", async (message) => {
  console.log(message.eventType, message.event.id);

  if (message.eventType.startsWith("game.")) {
    await queryClient.invalidateQueries({
      queryKey: ["game", { gameId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["games"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["currentGame"],
    });

    if (message.eventType == "game.finished") {
      await queryClient.invalidateQueries({
        queryKey: ["leaderboard"],
      });
    }

    if (
      message.eventType == "game.goalScored" ||
      message.eventType == "game.goalRevoked"
    ) {
      await queryClient.invalidateQueries({
        queryKey: ["topscorer"],
      });
    }

    return;
  }

  if (message.eventType.startsWith("team.")) {
    await queryClient.invalidateQueries({
      queryKey: ["team", { teamId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["teams"],
    });
    return;
  }

  if (message.eventType.startsWith("player.")) {
    await queryClient.invalidateQueries({
      queryKey: ["player", { playerId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["players"],
    });
    return;
  }

  if (message.eventType.startsWith("competition.")) {
    await queryClient.invalidateQueries({
      queryKey: ["competition", { competitionId: message.event.id }],
    });
    await queryClient.invalidateQueries({
      queryKey: ["competitions"],
    });
    await queryClient.invalidateQueries({
      queryKey: ["leaderboard"],
    });
    return;
  }
});

root.render(
  <React.StrictMode>
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Chivo",
        fontFamilyMonospace: "Chivo Mono",
        colors: {
          fwcBlue: [
            "#65B4FF",
            "#0080FF",
            "#005CFF",
            "#004BD3",
            "#0A3F86",
            "#002A77",
            "#001D5D",
            "#001543",
            "#000F30",
            "#000B23",
          ],
        },
        primaryColor: "fwcBlue",
      }}
    >
      <QueryClientProvider client={queryClient}>
        <DeviceTags>
          <RouterProvider router={routes} />
          <ReactQueryDevtools initialIsOpen={false} />
        </DeviceTags>
      </QueryClientProvider>
    </MantineProvider>
  </React.StrictMode>,
);
