import { TeamName } from "../../../../Components/TeamName";
import { TeamLogo } from "../../../../Components/TeamLogo";
import { FC, useEffect, useState } from "react";
import {
  Button,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { DateTime, Duration } from "luxon";
import {
  livestreamRegieReady,
  postponeGame,
} from "../../../../openapi/api-client";
import { useMutation } from "@tanstack/react-query";
import { useGame } from "../../../../hooks/useGame.ts";

type RegieGameDetailsProps = {
  gameId: string;
};

export const RegieGameDetails: FC<RegieGameDetailsProps> = (props) => {
  const { gameId } = props;

  const regieReadyMutation = useMutation({ mutationFn: livestreamRegieReady });
  const onClickRegieReady = async () => {
    await regieReadyMutation.mutateAsync({
      id: gameId,
    });
  };

  const gameData = useGame(gameId);

  const postponeGameMutation = useMutation({ mutationFn: postponeGame });
  const onClickPostpone = async (minutes: number) => {
    await postponeGameMutation.mutateAsync({
      id: gameId,
      postponedToDateTime: DateTime.now()
        .plus(Duration.fromObject({ minutes }))
        .toISO(),
    });
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!gameData.isSuccess) {
    return <Loader />;
  }

  const game = gameData.data;

  return (
    <Paper style={{ textAlign: "center" }} p="lg" shadow="sm">
      <Title order={1} mb="md">
        {game.name}
      </Title>
      <Stack>
        {game.actualTeams.homeTeamId && game.actualTeams.guestTeamId ? (
          <Group position="center">
            <div>
              <Text weight={700} fz="xl" ta="right">
                <TeamName teamId={game.actualTeams.homeTeamId} />
              </Text>
            </div>
            <div>
              <TeamLogo teamId={game.actualTeams.homeTeamId} height={50} />
            </div>
            <div>
              <TeamLogo teamId={game.actualTeams.guestTeamId} height={50} />
            </div>
            <div>
              <Text weight={700} fz="xl" ta="left">
                <TeamName teamId={game.actualTeams.guestTeamId} />
              </Text>
            </div>
          </Group>
        ) : (
          <Text weight={700} fz="xl">
            Teams stehen noch nicht fest
          </Text>
        )}
        <div>
          <Button
            onClick={onClickRegieReady}
            size="xl"
            disabled={game.livestreamRegieReady}
            color="green"
          >
            Regie bereit
          </Button>
        </div>
        <Text>
          Spielplan: {DateTime.fromISO(game.dateTime).toFormat("HH:mm")} Uhr
        </Text>
        <Text size={24} fw={700}>
          {game.livestreamRegieReady ? (
            <>&nbsp;</>
          ) : (
            DateTime.fromISO(game.postponedDateTime)
              .diff(DateTime.fromJSDate(currentTime))
              .toFormat("mm:ss")
          )}
        </Text>

        <Group position="center">
          <Button
            onClick={() => onClickPostpone(1)}
            disabled={game.livestreamRegieReady}
          >
            1 Min.
          </Button>
          <Button
            onClick={() => onClickPostpone(3)}
            disabled={game.livestreamRegieReady}
          >
            3 Min.
          </Button>
          <Button
            onClick={() => onClickPostpone(5)}
            disabled={game.livestreamRegieReady}
          >
            5 Min.
          </Button>
        </Group>
      </Stack>
    </Paper>
  );
};
