import { listCompetitions } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useCompetitionList = () => {
  return useQuery({
    queryKey: ["competitions"],
    queryFn: async () => {
      const response = await listCompetitions({});
      return response.data;
    },
  });
};
