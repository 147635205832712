import { FC, useState } from "react";
import { Button } from "@mantine/core";
import { TeamPlayerModal } from "./TeamPlayerModal";
import { IconClockPause } from "@tabler/icons-react";
import { TeamName } from "../../../../Components/TeamName";
import { useMutation } from "@tanstack/react-query";
import { gameGiveTimePenalty } from "../../../../openapi/api-client";
import { v4 as uuid } from "uuid";

type TimePenaltyButtonProps = {
  disabled?: boolean;
  teamId: string;
  gameId: string;
  time: number;
};

export const TimePenaltyButton: FC<TimePenaltyButtonProps> = (props) => {
  const { disabled, teamId, time, gameId } = props;
  const [modalOpened, setModalOpened] = useState(false);
  const [timePenaltyAt, setTimePenaltyAt] = useState<number | undefined>();
  const giveTimePenaltyMutation = useMutation({
    mutationFn: gameGiveTimePenalty,
  });

  return (
    <>
      <TeamPlayerModal
        teamId={teamId}
        opened={modalOpened}
        allDisabled={false}
        close={() => setModalOpened(false)}
        onClick={async (playerId) => {
          if (!playerId) {
            throw new Error("player id not provided");
          }

          if (timePenaltyAt == undefined) {
            throw new Error("time not set");
          }

          await giveTimePenaltyMutation.mutateAsync({
            id: gameId,
            playerId,
            timePenaltyId: uuid(),
            time: timePenaltyAt,
            teamId,
          });

          setModalOpened(false);
          setTimePenaltyAt(undefined);
        }}
        buttonColor="blue"
        headline={
          <>
            Zeitstrafe für <TeamName teamId={teamId} />
          </>
        }
      />
      <Button
        fullWidth
        color="grape"
        size="xl"
        leftIcon={<IconClockPause />}
        disabled={disabled}
        onClick={() => {
          setTimePenaltyAt(time);
          setModalOpened(true);
        }}
      >
        2 Min.
      </Button>
    </>
  );
};
