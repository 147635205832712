import { FC, ReactNode } from "react";
import { Skeleton } from "@mantine/core";

type TextSkeletonProps = {
  children: ReactNode;
};

export const TextSkeleton: FC<TextSkeletonProps> = (props) => {
  return <Skeleton style={{ display: "inline" }}>{props.children}</Skeleton>;
};
