import { FC } from "react";
import { TeamName } from "../../../../Components/TeamName";
import { ResolvedTeamReference } from "../../../../Components/ResolvedTeamReference";
import { ActionIcon, Table } from "@mantine/core";
import { components } from "../../../../openapi/generated-types";
import { DateTime } from "luxon";
import { IconBrandYoutube, IconPhoto } from "@tabler/icons-react";

type GameListProps = {
  games: components["schemas"]["Game"][];
};

export const GameList: FC<GameListProps> = (props) => {
  const { games } = props;

  return (
    <Table verticalSpacing="xs">
      <thead>
        <tr>
          <th style={{ width: "1%", textAlign: "right" }}>#</th>
          <th style={{ width: "1%" }}>Zeit</th>
          <th>Paarung</th>
          <th style={{ width: "1%" }}></th>
          <th style={{ width: "1%" }}></th>
        </tr>
      </thead>
      <tbody>
        {games.map((g) => (
          <tr key={g.id}>
            <td>{g.name.replace("Spiel ", "")}</td>
            <td style={{ whiteSpace: "nowrap" }}>
              {DateTime.fromISO(g.dateTime).toFormat("ccc., HH:mm")}
            </td>
            <td colSpan={g.result ? 1 : 3}>
              {g.actualTeams.homeTeamId && g.actualTeams.guestTeamId ? (
                <>
                  <span
                    style={{
                      fontWeight:
                        g.result?.winnerTeamId == g.actualTeams.homeTeamId
                          ? 700
                          : 400,
                    }}
                  >
                    <TeamName short teamId={g.actualTeams.homeTeamId} />
                  </span>
                  &nbsp;–&nbsp;
                  <span
                    style={{
                      fontWeight:
                        g.result?.winnerTeamId == g.actualTeams.guestTeamId
                          ? 700
                          : 400,
                    }}
                  >
                    <TeamName short teamId={g.actualTeams.guestTeamId} />
                  </span>
                </>
              ) : (
                <>
                  {g.actualTeams.homeTeamId ? (
                    <TeamName short teamId={g.actualTeams.homeTeamId} />
                  ) : (
                    <ResolvedTeamReference
                      teamReference={g.plannedTeams.home}
                      competitionId={g.competitionId}
                    />
                  )}
                  {g.actualTeams.homeTeamId || g.actualTeams.guestTeamId ? (
                    <>&nbsp;–&nbsp;</>
                  ) : (
                    <br />
                  )}
                  {g.actualTeams.guestTeamId ? (
                    <TeamName short teamId={g.actualTeams.guestTeamId} />
                  ) : (
                    <ResolvedTeamReference
                      teamReference={g.plannedTeams.guest}
                      competitionId={g.competitionId}
                    />
                  )}
                </>
              )}
            </td>

            {g.result && (
              <>
                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                  {g.score.home}:{g.score.guest}
                  {g.shootOutStarted && (
                    <>
                      {" "}
                      (
                      {g.score.home +
                        g.shootOutRounds.reduce(
                          (previous, round) =>
                            round.home?.success ? previous + 1 : previous,
                          0,
                        )}
                      :
                      {g.score.guest +
                        g.shootOutRounds.reduce(
                          (previous, round) =>
                            round.guest?.success ? previous + 1 : previous,
                          0,
                        )}
                      )
                    </>
                  )}
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: 6,
                    }}
                  >
                    <ActionIcon
                      color="fwcBlue"
                      size="sm"
                      disabled={!g.links.youtube}
                      onClick={
                        g.links.youtube
                          ? () => window.open(g.links.youtube, "_blank")
                          : undefined
                      }
                    >
                      <IconBrandYoutube />
                    </ActionIcon>
                    <ActionIcon
                      color="fwcBlue"
                      size="sm"
                      disabled={!g.links.gallery}
                      onClick={
                        g.links.gallery
                          ? () => window.open(g.links.gallery, "_blank")
                          : undefined
                      }
                    >
                      <IconPhoto />
                    </ActionIcon>
                  </div>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
