// generated with https://csvjson.com/csv2json from combined spreadsheet

import { operations } from "./openapi/generated-types.ts";

type CreatePlayer =
  operations["player.write.create"]["requestBody"]["content"]["application/json"];

export const players: (Omit<CreatePlayer, "teamId"> & {
  teamShortName: string;
})[] = [];
