import { FC } from "react";
import { IconBallFootball, IconFlag } from "@tabler/icons-react";
import { Loader, Timeline } from "@mantine/core";
import { Goal } from "./TimelineItems/Goal";
import { TeamName } from "../../../../Components/TeamName";
import { useGame } from "../../../../hooks/useGame.ts";

type GoalTimelineProps = {
  gameId: string;
};

export const GameTimeline: FC<GoalTimelineProps> = (props) => {
  const { gameId } = props;

  const game = useGame(gameId);

  if (!game.isSuccess) {
    return <Loader />;
  }

  const goals = [...game.data.goals].reverse();

  return (
    <Timeline bulletSize={24} lineWidth={2}>
      {goals.map((g) => (
        <Timeline.Item
          bullet={<IconBallFootball size={12} />}
          title={
            <>
              {g.isOwnGoal ? "Eigentor" : "Tor"} für{" "}
              <TeamName
                teamId={
                  g.homeTeam
                    ? game.data.actualTeams.homeTeamId!
                    : game.data.actualTeams.guestTeamId!
                }
              />
            </>
          }
          key={g.goalId}
        >
          <Goal gameId={gameId} goalId={g.goalId} />
        </Timeline.Item>
      ))}

      {game.data.started && (
        <Timeline.Item bullet={<IconFlag size={12} />} title="Anpfiff" />
      )}
    </Timeline>
  );
};
