import { Page } from "../../../Components/Page";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { gameChangeShootOutShotsNumber } from "../../../openapi/api-client";
import { Button, Group, Loader, NumberInput, Stack } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { useGame } from "../../../hooks/useGame.ts";

export const ChangeShootOutShotsNumber: FC = () => {
  const { gameId } = useRequiredParams(["gameId"]);
  const navigate = useNavigate();
  const game = useGame(gameId);

  const form = useForm<{
    shootOutShotsNumber: number;
  }>({
    initialValues: {
      shootOutShotsNumber: game.data?.shootOutShotsNumber ?? 0,
    },
  });

  useEffect(() => {
    if (!game.data) {
      return;
    }
    form.setFieldValue("shootOutShotsNumber", game.data.shootOutShotsNumber);
  }, [game.data]);

  const changeShootOutShotsNumberMutation = useMutation({
    mutationFn: gameChangeShootOutShotsNumber,
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (!game.isSuccess) {
      throw new Error("game not loaded");
    }

    console.log(values);

    await changeShootOutShotsNumberMutation.mutateAsync({
      id: game.data.id,
      shootOutShotsNumber: values.shootOutShotsNumber,
    });

    navigate(`../../..`, { relative: "path" });
  });

  if (!game.isSuccess) {
    return <Loader />;
  }

  return (
    <Page
      title={`Anzahl der Schüsse beim 9 m-Schießen von ${game.data.name} ändern`}
    >
      Hilfe: {game.data.duration / 60}
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <Group align="end">
            <NumberInput
              label="Schüsse"
              required
              {...form.getInputProps("shootOutShotsNumber")}
            />
            <Button
              onClick={() => form.setFieldValue("shootOutShotsNumber", 3)}
            >
              3
            </Button>
            <Button
              onClick={() => form.setFieldValue("shootOutShotsNumber", 5)}
            >
              5
            </Button>
          </Group>

          <Group mt="md">
            <Button type="submit">9 m-Schüsse ändern</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
