import { FC, useState } from "react";
import { Outlet } from "react-router-dom";
import { CompetitionContext } from "../../CompetitionContext.tsx";
import { useCommand } from "../../hooks/useCommand.ts";
import { CompetitionCommand } from "../../commandTypes.ts";

export const Streamelements: FC = () => {
  const showBackground = import.meta.env.DEV;

  const [competitionId, setCompetitionId] = useState<string>(
    import.meta.env.VITE_APP_COMPETITION_ID,
  );

  useCommand<CompetitionCommand>("competition", (c) => {
    setCompetitionId(c.competitionId);
  });

  return (
    <CompetitionContext.Provider value={competitionId}>
      <div
        style={{
          width: 1920,
          height: 1080,
          backgroundImage: showBackground
            ? "url(/img/screenshot-stream.jpg)"
            : undefined,
        }}
      >
        <Outlet />
      </div>
    </CompetitionContext.Provider>
  );
};
