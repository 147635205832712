import { FC } from "react";
import { gameSetLinks } from "../../../../openapi/api-client";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "@mantine/form";
import { Button, Group, Stack, TextInput, Title } from "@mantine/core";
import {
  IconBrandYoutube,
  IconDeviceFloppy,
  IconPhoto,
} from "@tabler/icons-react";
import { useGame } from "../../../../hooks/useGame.ts";

type LinksFormProps = {
  gameId: string;
};

export const LinksForm: FC<LinksFormProps> = (props) => {
  const { gameId } = props;

  const setLinksMutation = useMutation({ mutationFn: gameSetLinks });

  const gameData = useGame(gameId);

  const linksForm = useForm<{ youtube: string; gallery: string }>({
    initialValues: {
      youtube: gameData.data?.links.youtube ?? "",
      gallery: gameData.data?.links.gallery ?? "",
    },
  });

  const onSubmit = linksForm.onSubmit(async (values) => {
    await setLinksMutation.mutateAsync({
      id: gameId,
      gallery: values.gallery == "" ? undefined : values.gallery,
      youtube: values.youtube == "" ? undefined : values.youtube,
    });
  });

  return (
    <>
      <Title order={3}>Medien-Links</Title>
      <form onSubmit={onSubmit}>
        <Stack>
          <TextInput
            icon={<IconBrandYoutube />}
            placeholder="https://youtube.com/view?v=..."
            label="YouTube"
            {...linksForm.getInputProps("youtube")}
          />

          <TextInput
            icon={<IconPhoto />}
            placeholder="https://photos.google.com/..."
            label="Galerie"
            {...linksForm.getInputProps("gallery")}
          />

          <Group mt="md">
            <Button
              type="submit"
              leftIcon={<IconDeviceFloppy />}
              loading={setLinksMutation.isPending}
            >
              Links speichern
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
