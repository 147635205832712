import { Button } from "@mantine/core";
import { FC } from "react";

type PlayerButtonProps = {
  onClick: () => void;
  number: number;
  color: string;
  disabled: boolean;
};

export const PlayerButton: FC<PlayerButtonProps> = (props) => {
  const { onClick, number, color, disabled } = props;

  return (
    <Button
      onClick={onClick}
      styles={() => ({
        root: { height: 100, fontSize: 30 },
      })}
      fullWidth
      color={color}
      disabled={disabled}
    >
      {number}
    </Button>
  );
};
