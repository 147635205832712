import React, { useEffect } from "react";
import { useLocalStorage } from "@mantine/hooks";

export const DeviceTagContext = React.createContext<{
  deviceTags: string[];
  setDeviceTags: (tags: string[]) => void;
}>({
  deviceTags: [],
  setDeviceTags: () => {},
});

export const DeviceTags = (props: { children: React.ReactNode }) => {
  const [localStorageDeviceTags, setLocalStorageDeviceTags] = useLocalStorage({
    key: "deviceTags",
    defaultValue: "",
  });

  const deviceTags = localStorageDeviceTags.split(",");
  const setDeviceTags = (t: string[]) => {
    setLocalStorageDeviceTags([...t, "all"].join(","));
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    const searchParamTags = searchParams.get("deviceTags");
    if (!searchParamTags) {
      return;
    }

    setDeviceTags(searchParamTags.split(","));
    searchParams.delete("deviceTags");

    window.location.search = searchParams.toString();
  }, []);

  return (
    <DeviceTagContext.Provider value={{ deviceTags, setDeviceTags }}>
      {props.children}
    </DeviceTagContext.Provider>
  );
};
