import { getTeam } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useTeam = (teamId?: string) => {
  return useQuery({
    queryKey: ["team", { teamId }],
    queryFn: async () => {
      if (!teamId) {
        throw new Error("teamId not set");
      }
      const response = await getTeam({ teamId });
      return response.data;
    },
    enabled: !!teamId,
  });
};
