import {
  AppShell,
  Burger,
  Group,
  Header,
  MediaQuery,
  Navbar,
} from "@mantine/core";
import { Logo } from "../../Logo";
import { FC } from "react";
import {
  IconChairDirector,
  IconCommand,
  IconMicrophone,
  IconShirtSport,
  IconTournament,
  IconUser,
} from "@tabler/icons-react";
import { Outlet, useNavigate } from "react-router-dom";
import { NavItem } from "../../Components/NavItem";
import { useToggle } from "@mantine/hooks";

export const Control: FC = () => {
  const navigate = useNavigate();
  const [navbarOpen, toggleNavbarOpen] = useToggle<boolean>([false, true]);

  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="md"
      asideOffsetBreakpoint="md"
      navbar={
        <Navbar
          width={{ md: 200 }}
          p="xs"
          hiddenBreakpoint="md"
          hidden={!navbarOpen}
        >
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("competitions");
            }}
            color="blue"
            icon={<IconTournament size={16} />}
            label="Turniere"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("teams");
            }}
            color="yellow"
            icon={<IconShirtSport size={16} />}
            label="Mannschaften"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("players");
            }}
            color="red"
            icon={<IconUser size={16} />}
            label="Spieler"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("timekeeper");
            }}
            color="cyan"
            icon={<IconCommand size={16} />}
            label="Zeitnehmer"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("regie");
            }}
            color="pink"
            icon={<IconChairDirector size={16} />}
            label="Regie"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("ledwall");
            }}
            color="lime"
            icon={<IconUser size={16} />}
            label="Ledwall"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("commentator");
            }}
            color="violet"
            icon={<IconMicrophone size={16} />}
            label="Kommentator"
          />
          <NavItem
            onClick={() => {
              toggleNavbarOpen(false);
              navigate("command");
            }}
            color="orange"
            icon={<IconCommand size={16} />}
            label="Commands"
          />
        </Navbar>
      }
      header={
        <Header height={60}>
          <Group sx={{ height: "100%" }} px={20} position="apart">
            <Logo onClick={() => {}} />
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger
                opened={navbarOpen}
                onClick={() => toggleNavbarOpen()}
                size="md"
              />
            </MediaQuery>
          </Group>
        </Header>
      }
      styles={(theme) => ({
        main: { backgroundColor: theme.colors.gray[0] },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default Control;
