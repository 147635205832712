import React, { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlayer, getTopscorer } from "../../../openapi/api-client";
import { TeamName } from "../../../Components/TeamName";
import { PlayerNumber } from "../../../Components/PlayerNumber";
import { PlayerName } from "../../../Components/PlayerName";
import { Group, Loader, Text, Image } from "@mantine/core";
import { CompetitionContext } from "../../../CompetitionContext.tsx";

const tdStyle: React.CSSProperties = {
  paddingTop: 15,
  paddingBottom: 15,
  paddingLeft: 30,
  paddingRight: 30,
  color: "white",
};

export const TopscorerFullscreen: FC = () => {
  const competitionId = useContext(CompetitionContext);

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  if (!topscorerData.isSuccess) {
    return <></>;
  }

  const scorer = topscorerData.data.topscorer.slice(0, 5);

  return (
    <div
      style={{
        backgroundColor: "black",
        width: 1920,
        height: 1080,
        padding: 50,
      }}
    >
      <Group position="apart">
        <Text size={110} c="white" weight={900}>
          Unsere Topscorer
        </Text>
        <div>
          <Image
            src="https://app-assets.freewaycup.de/sponsorlogo/swierzy-logo-auf-schwarz.svg"
            fit="contain"
            height={150}
          />
        </div>
      </Group>

      <table
        style={{
          width: "100%",
          fontSize: 64,
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr
            style={{
              borderBottom: "10px solid white",
            }}
          >
            <th style={{ ...tdStyle }}></th>
            <th style={{ ...tdStyle }}></th>
            <th style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}>
              T
            </th>
            <th style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}>
              A
            </th>
            <th style={{ ...tdStyle, textAlign: "center", fontWeight: 700 }}>
              S
            </th>
          </tr>
        </thead>
        <tbody>
          {scorer.map((t, index) => (
            <PlayerRow
              key={t.playerId}
              playerId={t.playerId}
              position={index + 1}
              assists={t.assists}
              goals={t.goals}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PlayerRow: FC<{
  playerId: string;
  position: number;
  assists: number;
  goals: number;
}> = (props) => {
  const { playerId, position, assists, goals } = props;

  const playerData = useQuery({
    queryKey: ["player", { playerId }],
    queryFn: async () => {
      const response = await getPlayer({ playerId });
      return response.data;
    },
  });

  if (!playerData.isSuccess) {
    return (
      <tr>
        <td colSpan={5}>
          <Loader />
        </td>
      </tr>
    );
  }

  return (
    <tr style={{ borderBottom: "5px solid #444444" }}>
      <td style={{ ...tdStyle, textAlign: "right" }}>{position}.</td>
      <td style={{ ...tdStyle }}>
        <PlayerName firstname playerId={playerId} /> (
        <TeamName short teamId={playerData.data.teamId} />,{" "}
        <PlayerNumber playerId={playerId} />)
      </td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{goals}</td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{assists}</td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{goals + assists}</td>
    </tr>
  );
};
