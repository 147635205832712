import React from "react";
import { useGame } from "../../../../../hooks/useGame.ts";
import { useTeam } from "../../../../../hooks/useTeam.ts";

type SponsorProps = {
  gameId: string;
  team: "home" | "guest";
};

export const Sponsor: React.FC<SponsorProps> = (props) => {
  const { gameId, team: propsTeam } = props;

  const game = useGame(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId;
  const guestTeamId = game.data?.actualTeams.guestTeamId;

  const team = useTeam(propsTeam == "home" ? homeTeamId : guestTeamId);

  const dataLoaded = game.isSuccess && team.isSuccess;

  const filePath = (team.data?.sponsor?.ledwallFilePath ?? "")
    .replace("$width", "1920")
    .replace("$height", "144")
    .replace("$screenId", "1");

  console.log(team.data?.id, filePath);

  return (
    <div
      style={{
        backgroundColor: "black",
        height: 144,
        width: 1200,
      }}
    >
      {dataLoaded && filePath && (
        <img src={filePath} alt={team.data?.sponsor?.name} />
      )}
    </div>
  );
};
