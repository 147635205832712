import { FC } from "react";
import { Burger, Header, Image, MediaQuery, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../../../Logo";

type AppHeaderProps = {
  toggleNavbarOpen: (value?: boolean) => void;
  navbarOpen: boolean;
};

export const AppHeader: FC<AppHeaderProps> = (props) => {
  const { toggleNavbarOpen, navbarOpen } = props;
  const navigate = useNavigate();

  return (
    <>
      <Header height={{ base: 70, md: 70 }} p="md">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Logo
            onClick={() => {
              navigate("/");
              toggleNavbarOpen(false);
            }}
          />

          <div style={{ display: "flex", alignItems: "center" }}>
            <Text size={12} align="center" style={{ lineHeight: 1.1 }}>
              App
              <br />
              präsentiert
              <br />
              von
            </Text>
            <div
              style={{
                height: 38,
                width: 38,
                marginLeft: 10,
              }}
            >
              <Image
                alt="Marktkauf"
                src="https://app-assets.freewaycup.de/sponsorlogo/marktkauf.svg"
              />
            </div>
          </div>

          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Burger
              opened={navbarOpen}
              onClick={() => toggleNavbarOpen()}
              size="md"
              title="Navigation"
            />
          </MediaQuery>
        </div>
      </Header>
    </>
  );
};
