import { useQuery } from "@tanstack/react-query";
import { getGame } from "../openapi/api-client";

export const useShootoutRounds = (gameId?: string) => {
  const gameData = useQuery({
    queryKey: ["game", { gameId }],
    queryFn: async () => {
      if (!gameId) {
        throw new Error("game id not given");
      }

      const response = await getGame({ gameId });
      return response.data;
    },
    enabled: !!gameId,
  });

  if (!gameData.isSuccess) {
    return null;
  }

  const lastRound = gameData.data.shootOutRounds.at(-1);
  const lastRoundCompleted = lastRound?.home && lastRound?.guest;
  const numRounds = Math.max(
    gameData.data.shootOutShotsNumber,
    lastRoundCompleted && !gameData.data.result
      ? gameData.data.shootOutRounds.length + 1
      : gameData.data.shootOutRounds.length,
  );
  const emptyRounds = Array(numRounds).fill({});

  return emptyRounds.map(
    (_value, index) => gameData.data.shootOutRounds?.at(index) ?? {},
  );
};
