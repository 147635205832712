import { FC } from "react";
import { Image } from "@mantine/core";
import { getTeam } from "../openapi/api-client";
import { useQuery } from "@tanstack/react-query";

type TeamLogoProps = {
  teamId: string;
  height?: number;
};

export const TeamLogo: FC<TeamLogoProps> = (props) => {
  const { teamId, height } = props;

  const teamData = useQuery({
    queryKey: ["team", { teamId }],
    queryFn: async () => {
      const response = await getTeam({ teamId });
      return response.data;
    },
  });

  return (
    <Image
      src={teamData.data?.logoFilePath}
      withPlaceholder
      height={height}
      fit="contain"
      alt={"Logo " + teamData.data?.shortName}
    />
  );
};
