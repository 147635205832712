import { FC } from "react";
import { Page } from "../../../Components/Page";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "@mantine/form";
import { createTeam } from "../../../openapi/api-client";
import { v4 as uuid } from "uuid";
import {
  Button,
  ColorInput,
  Group,
  Stack,
  TextInput,
  Title,
} from "@mantine/core";

export const TeamNew: FC = () => {
  const navigate = useNavigate();

  const form = useForm<{
    name: string;
    shortName: string;
    primaryColor: string;
    logoFilePath: string;
    sponsorName: string;
    sponsorUrl: string;
    sponsorLogoFilePath: string;
    sponsorLedwallFilePath: string;
    photoFilePath: string;
    photoDescription: string;
  }>({
    initialValues: {
      name: "",
      shortName: "",
      primaryColor: "#000000",
      logoFilePath: "",
      photoFilePath: "",
      photoDescription: "",
      sponsorLogoFilePath: "",
      sponsorLedwallFilePath: "",
      sponsorName: "",
      sponsorUrl: "",
    },
  });

  const createTeamMutation = useMutation({ mutationFn: createTeam });

  const onSubmit = form.onSubmit(async (values) => {
    await createTeamMutation.mutateAsync({
      id: uuid(),
      name: values.name,
      shortName: values.shortName,
      primaryColor: values.primaryColor,
      logoFilePath: values.logoFilePath != "" ? values.logoFilePath : undefined,
      photo:
        values.photoFilePath != ""
          ? {
              description:
                values.photoDescription != ""
                  ? values.photoDescription
                  : undefined,
              filePath: values.photoFilePath,
            }
          : undefined,
      sponsor:
        values.sponsorName != ""
          ? {
              name: values.sponsorName,
              logoFilePath:
                values.sponsorLogoFilePath != ""
                  ? values.sponsorLogoFilePath
                  : undefined,
              ledwallFilePath:
                values.sponsorLedwallFilePath != ""
                  ? values.sponsorLedwallFilePath
                  : undefined,
              url: values.sponsorUrl != "" ? values.sponsorUrl : undefined,
            }
          : undefined,
    });

    navigate("..", { relative: "path" });
  });

  return (
    <Page title="Neue Mannschaft">
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <TextInput
            placeholder="FC Bayern München"
            label="Name"
            required
            {...form.getInputProps("name")}
          />

          <TextInput
            placeholder="FCB"
            label="Kurz-Name"
            required
            {...form.getInputProps("shortName")}
          />

          <TextInput
            label="Pfad zum Logo"
            {...form.getInputProps("logoFilePath")}
          />

          {form.values.logoFilePath != "" && (
            <img src={form.values.logoFilePath} width="100px" />
          )}

          <ColorInput
            placeholder="Farbe auswählen"
            label="Primäre Farbe"
            withEyeDropper
            {...form.getInputProps("primaryColor")}
          />

          <Title order={3}>Mannschaftsfoto</Title>
          <TextInput
            label="Pfad zum Foto"
            {...form.getInputProps("photoFilePath")}
          />
          <TextInput
            label="Beschreibung"
            {...form.getInputProps("photoDescription")}
          />

          {form.values.photoFilePath != "" && (
            <img src={form.values.photoFilePath} height="200px" />
          )}

          <Title order={3}>Sponsor</Title>
          <TextInput label="Name" {...form.getInputProps("sponsorName")} />
          <TextInput label="Website" {...form.getInputProps("sponsorUrl")} />
          <TextInput
            label="Pfad zum Logo"
            {...form.getInputProps("sponsorLogoFilePath")}
          />
          <TextInput
            label="Pfad zum Ledwall-File"
            description="Variablen: $height, $width, $screenId"
            {...form.getInputProps("sponsorLedwallFilePath")}
          />

          {form.values.sponsorLogoFilePath != "" && (
            <img src={form.values.sponsorLogoFilePath} height="100px" />
          )}

          <Group mt="md">
            <Button type="submit">Mannschaft anlegen</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
