import { getPlayer } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const usePlayer = (playerId?: string) => {
  return useQuery({
    queryKey: ["player", { playerId }],
    queryFn: async () => {
      if (!playerId) {
        throw new Error("player id not set");
      }

      const response = await getPlayer({ playerId });
      return response.data;
    },
    enabled: !!playerId,
  });
};
