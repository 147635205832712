import { FC } from "react";
import { Image } from "@mantine/core";

type LogoProps = {
  onClick: () => void;
};

export const Logo: FC<LogoProps> = (props) => {
  const { onClick } = props;

  return (
    <div
      style={{
        flexShrink: 0,
      }}
    >
      <Image
        src="/img/logo-fwc.png"
        height={30}
        width={118}
        fit="contain"
        onClick={onClick}
        alt="FreewayCup"
      />
    </div>
  );
};
