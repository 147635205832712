import { Button, Loader, Modal, SimpleGrid, Title } from "@mantine/core";
import { FC, ReactNode } from "react";
import { PlayerButton } from "./PlayerButton";
import { usePlayers } from "../../../../hooks/usePlayers.ts";

type TeamPlayerModalProps = {
  teamId: string;
  opened: boolean;
  close: () => void;
  onClick: (playerId?: string) => void;
  showEmptyPlayer?: boolean;
  emptyPlayerText?: ReactNode;
  emptyPlayerAction?: () => void;
  headline: ReactNode;
  buttonColor: string;
  disabledPlayerIds?: string[];
  allDisabled: boolean;
};

export const TeamPlayerModal: FC<TeamPlayerModalProps> = (props) => {
  const {
    teamId,
    opened,
    close,
    onClick,
    showEmptyPlayer,
    emptyPlayerText,
    emptyPlayerAction,
    headline,
    buttonColor,
    disabledPlayerIds,
    allDisabled,
  } = props;

  const players = usePlayers(teamId);

  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={close}
      centered
      closeOnClickOutside={false}
      closeOnEscape={true}
      trapFocus={true}
      withCloseButton={false}
    >
      <Title mb="xl">{headline}</Title>
      <SimpleGrid cols={5} spacing="xl">
        {!players.isSuccess ? (
          <Loader />
        ) : (
          <>
            {players.data.map((p) => (
              <PlayerButton
                key={p.id}
                onClick={() => onClick(p.id)}
                number={p.number}
                color={buttonColor}
                disabled={!!disabledPlayerIds?.includes(p.id) || allDisabled}
              />
            ))}
            {showEmptyPlayer && (
              <Button
                fullWidth
                color="red"
                disabled={allDisabled}
                onClick={() =>
                  emptyPlayerAction ? emptyPlayerAction() : onClick()
                }
                styles={() => ({
                  root: {
                    height: 100,
                    fontSize: 20,
                    textAlign: "center",
                    lineHeight: "1.3em",
                  },
                })}
              >
                {emptyPlayerText ?? "kein Spieler"}
              </Button>
            )}
          </>
        )}
      </SimpleGrid>
      <Button mt="xl" color="red" variant="outline" onClick={close}>
        Abbrechen
      </Button>
    </Modal>
  );
};
