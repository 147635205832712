import { useState } from "react";
import { useCommand } from "./useCommand.ts";
import { CommentatorOff, CommentatorOn } from "../commandTypes.ts";

export const useShowCommentatorNotification = (): boolean | undefined => {
  const [showNotification, setShowNotification] = useState<boolean | undefined>(
    undefined,
  );

  useCommand<CommentatorOn>("commentator.on", () => {
    setShowNotification(true);
  });
  useCommand<CommentatorOff>("commentator.off", () => {
    setShowNotification(false);
  });

  return showNotification;
};
