import { FC, useContext } from "react";
import { Stack, Table, Title, Text, Box } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../../../openapi/api-client";
import { TeamName } from "../../../../Components/TeamName";
import { CenteredLoader } from "../../../../Components/CenteredLoader";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";
import { TeamLogo } from "../../../../Components/TeamLogo.tsx";

export const Leaderboard: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const groupId = import.meta.env.VITE_APP_LEADERBOARD_GROUP_ID;

  const leaderboardData = useQuery({
    queryKey: ["leaderboard", { competitionId, groupId }],
    queryFn: async () => {
      const data = await getLeaderboard({ competitionId, groupId });
      return data.data;
    },
  });

  return (
    <>
      <Stack spacing="xl">
        <Title order={2}>Vorrundentabelle</Title>
        <Text>
          Die Tabelle errechnet sich auf Basis der Punkte, Tordifferenz und
          Anzahl geschossener Tore. Bei Gleichstand entscheidet der direkte
          Vergleich und ggf. ein 9-Meter-Schießen.
        </Text>
        {leaderboardData.isSuccess ? (
          <Table horizontalSpacing="xs">
            <thead>
              <tr>
                <th style={{ textAlign: "right", width: "1%" }}></th>
                <th style={{ textAlign: "right", width: "1%" }}></th>
                <th></th>
                <th style={{ textAlign: "center", width: "1%" }}>Tore</th>
                <th style={{ textAlign: "center", width: "1%" }}>D.</th>
                <th style={{ textAlign: "center", width: "1%" }}>P.</th>
              </tr>
            </thead>
            <tbody>
              {leaderboardData.data.leaderboard.map((l, index) => (
                <tr key={l.teamId}>
                  <td style={{ textAlign: "right" }}>{index + 1}.</td>
                  <td>
                    <Box w={20}>
                      <TeamLogo teamId={l.teamId} height={20} />
                    </Box>
                  </td>
                  <td>
                    <Text lineClamp={1} style={{ wordBreak: "break-all" }}>
                      <TeamName teamId={l.teamId} />
                    </Text>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {l.scoredGoals}:{l.receivedGoals}
                  </td>
                  <td style={{ textAlign: "center" }}>{l.goalDifference}</td>
                  <td style={{ textAlign: "center" }}>{l.points}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <CenteredLoader />
        )}
      </Stack>
    </>
  );
};
