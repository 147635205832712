import { FC, useState } from "react";
import { Page } from "../../../Components/Page";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { enrollTeam, listTeams } from "../../../openapi/api-client";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Group, Loader, MultiSelect, Stack } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useCompetition } from "../../../hooks/useCompetition.ts";

export const EnrollTeams: FC = () => {
  const { competitionId } = useRequiredParams(["competitionId"]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const competition = useCompetition(competitionId);

  const teams = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({});
      return response.data;
    },
  });

  const form = useForm<{ teamIds: string[] }>({
    initialValues: {
      teamIds: [],
    },
  });

  const enrollTeamMutation = useMutation({ mutationFn: enrollTeam });

  const onSubmit = form.onSubmit(async (values) => {
    setIsSubmitting(true);
    console.log(values);
    await Promise.all(
      values.teamIds.map((teamId) => {
        enrollTeamMutation.mutateAsync({ id: competitionId, teamId });
      }),
    );

    navigate(`../../`, { relative: "path" });
  });

  if (!competition.isSuccess || !teams.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={`Mannschaften zu ${competition.data.name} hinzufügen`}>
      <form onSubmit={onSubmit}>
        <Stack align="flex-stretch" justify="flex-start">
          <MultiSelect
            data={teams.data.teams
              .filter((t) => !competition.data.teamIds.includes(t.id))
              .map((t) => ({
                label: t.name,
                value: t.id,
              }))}
            label={"Mannschaften auswählen"}
            searchable
            nothingFound="keine passende Mannschaft gefunden"
            {...form.getInputProps("teamIds")}
          />
          <Group>
            <Button type="submit" loading={isSubmitting}>
              Mannschaften hinzufügen
            </Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
