import { FC } from "react";
import { TextSkeleton } from "./TextSkeleton";
import { useTeam } from "../hooks/useTeam.ts";

export const TeamName: FC<{ teamId: string; short?: boolean }> = (props) => {
  const { teamId, short } = props;
  const team = useTeam(teamId);

  if (!team.isSuccess) {
    return <TextSkeleton>Name</TextSkeleton>;
  }

  return <>{short ? team.data.shortName : team.data.name}</>;
};
