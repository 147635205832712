import { getGame } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useGame = (gameId?: string) => {
  return useQuery({
    queryKey: ["game", { gameId }],
    queryFn: async () => {
      if (!gameId) {
        throw new Error("game id not set");
      }

      const response = await getGame({ gameId });
      return response.data;
    },
    enabled: !!gameId,
  });
};
