import { FC } from "react";
import { Loader, Table, Title, Text, Stack } from "@mantine/core";
import { TeamName } from "../../../../Components/TeamName";
import { DateTime } from "luxon";
import { usePlayers } from "../../../../hooks/usePlayers.ts";
import { useTeam } from "../../../../hooks/useTeam.ts";

type TeamLineupProps = {
  teamId: string;
};

export const TeamLineup: FC<TeamLineupProps> = (props) => {
  const { teamId } = props;

  const players = usePlayers(teamId);
  const team = useTeam(teamId);

  if (!players.isSuccess || !team.isSuccess) {
    return <Loader />;
  }

  return (
    <Stack>
      <Title order={2}>
        <TeamName teamId={teamId} />
      </Title>
      <Text>{team.data.staff}</Text>
      <Table fontSize="lg" highlightOnHover mt="sm">
        <thead>
          <tr>
            <th>Nummer</th>
            <th>Nachname</th>
            <th>Vorname</th>
            <th>Geburtstag</th>
          </tr>
        </thead>
        <tbody>
          {players.data.map((p) => {
            const birthday = DateTime.fromISO(p.birthday);
            const age = Math.floor(DateTime.now().diff(birthday).as("year"));

            return (
              <tr key={p.id}>
                <td>{p.number}</td>
                <td>{p.lastName}</td>
                <td>{p.firstName}</td>
                <td>
                  {birthday.toFormat("dd.MM.yyyy")} ({age})
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Stack>
  );
};
