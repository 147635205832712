import { sendCommand as sendCommandToAPI } from "./openapi/api-client.ts";

export interface BaseCommand {
  command: string;
}

export interface CompetitionCommand {
  command: "competition";
  competitionId: string;
}

export interface LedwallDisable {
  command: "ledwall.disable";
}

export interface LedwallEnable {
  command: "ledwall.enable";
}

export interface LedwallActivityItemSelect {
  command: "ledwall.activityItem.select";
  activityItemId: string;
}

export interface LedwallActivityItemDisable {
  command: "ledwall.activityItem.disable";
}

export interface CommentatorOn {
  command: "commentator.on";
}

export interface CommentatorOff {
  command: "commentator.off";
}

export type Command =
  | CompetitionCommand
  | LedwallDisable
  | LedwallEnable
  | LedwallActivityItemSelect
  | LedwallActivityItemDisable
  | CommentatorOff
  | CommentatorOn;

export const sendCommand = async (command: Command) => {
  sendCommandToAPI({ ...command, tags: [] });
};
