import { Page } from "../../../Components/Page";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { gameChangeDuration } from "../../../openapi/api-client";
import { Button, Group, Loader, NumberInput, Stack } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { useRequiredParams } from "../../../hooks/useRequiredParams";
import { useGame } from "../../../hooks/useGame.ts";

export const ChangeDuration: FC = () => {
  const { gameId } = useRequiredParams(["gameId"]);
  const navigate = useNavigate();
  const game = useGame(gameId);

  const form = useForm<{
    durationInMinutes: number;
  }>({
    initialValues: {
      durationInMinutes: game.data?.duration ?? 0,
    },
  });

  useEffect(() => {
    if (!game.data) {
      return;
    }
    form.setFieldValue("durationInMinutes", game.data.duration / 60);
  }, [game.data]);

  const changeDurationMutation = useMutation({
    mutationFn: gameChangeDuration,
  });

  const onSubmit = form.onSubmit(async (values) => {
    if (!game.isSuccess) {
      throw new Error("game not loaded");
    }

    console.log(values);

    await changeDurationMutation.mutateAsync({
      id: game.data.id,
      duration: values.durationInMinutes * 60,
    });

    navigate(`../../..`, { relative: "path" });
  });

  if (!game.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={`Zeit von ${game.data.name} ändern`}>
      Hilfe: {game.data.duration / 60}
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <Group align="end">
            <NumberInput
              label="Spieldauer"
              description="Angabe in Minuten"
              required
              {...form.getInputProps("durationInMinutes")}
            />
            <Button onClick={() => form.setFieldValue("durationInMinutes", 12)}>
              12 Min.
            </Button>
            <Button onClick={() => form.setFieldValue("durationInMinutes", 15)}>
              15 Min.
            </Button>
            <Button onClick={() => form.setFieldValue("durationInMinutes", 10)}>
              10 Min.
            </Button>
          </Group>

          <Group mt="md">
            <Button type="submit">Spieldauer ändern</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
