import { FC } from "react";
import { Page } from "../../../Components/Page";
import { listTeams } from "../../../openapi/api-client";
import { useQuery } from "@tanstack/react-query";
import { Button, Loader, Table, Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { components } from "../../../openapi/generated-types";

type TableRowProps = {
  team: components["schemas"]["Team"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { team } = props;

  return (
    <tr>
      <td>
        <Avatar>{team.shortName}</Avatar>
      </td>
      <td>{team.name}</td>
      <td>{team.shortName}</td>
      <td>{team.sponsor?.name}</td>
    </tr>
  );
};

export const TeamOverview: FC = () => {
  const navigate = useNavigate();
  const teamData = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({});
      return response.data;
    },
  });

  if (!teamData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={"Mannschaften"}>
      <Button mb="md" onClick={() => navigate("new")}>
        Neue Mannschaft
      </Button>
      <Table>
        <thead>
          <tr>
            <th>Logo</th>
            <th>Name</th>
            <th>Kurzform</th>
            <th>Sponsor</th>
          </tr>
        </thead>
        <tbody>
          {teamData.data.teams.map((t) => (
            <TableRow key={t.id} team={t} />
          ))}
        </tbody>
      </Table>
    </Page>
  );
};
