import { FC } from "react";
import {
  AppShell,
  Image,
  useMantineTheme,
  Grid,
  Box,
  Stack,
} from "@mantine/core";
import { AppHeader } from "./Components/Layout/AppHeader";
import { useMediaQuery, useToggle } from "@mantine/hooks";
import { Outlet, ScrollRestoration, useOutlet } from "react-router-dom";
import { Homepage } from "./Components/Pages/Homepage";
import { CurrentGame } from "./Components/Widgets/CurrentGame";
import { Navigation } from "./Components/Layout/Navigation";
import { Stream } from "./Components/Widgets/Stream";
import { OfflineIndicator } from "../../Components/OfflineIndicator.tsx";

export const App: FC = () => {
  const theme = useMantineTheme();
  const [navbarOpened, toggleNavbarOpen] = useToggle<boolean>();
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const outlet = useOutlet();

  return (
    <>
      <ScrollRestoration />
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navigation
            navbarOpened={navbarOpened}
            closeNavbar={() => toggleNavbarOpen(false)}
          />
        }
        header={
          <AppHeader
            navbarOpen={navbarOpened}
            toggleNavbarOpen={toggleNavbarOpen}
          />
        }
      >
        <Stack spacing="xl">
          <OfflineIndicator />
          {isDesktop ? (
            <Grid>
              <Grid.Col span={4}>
                {outlet != null ? <Outlet /> : <Homepage isDesktop />}
              </Grid.Col>
              <Grid.Col span={8}>
                <Box w="100%" style={{ position: "sticky", top: 86 }}>
                  <Stream />
                  <Image
                    src="https://app-assets.freewaycup.de/sponsorads/anzeige-marktkauf.jpg"
                    mt="lg"
                    alt=""
                  />
                </Box>
              </Grid.Col>
            </Grid>
          ) : (
            <>
              <CurrentGame />
              {outlet != null ? <Outlet /> : <Homepage />}
              <Image
                src="https://app-assets.freewaycup.de/sponsorads/anzeige-marktkauf.jpg"
                mt="lg"
                alt=""
              />
            </>
          )}
        </Stack>

        <div style={{ color: "#333", marginTop: 30, fontSize: 12 }}>
          © 2024, FreewayCup -{" "}
          <a href="https://www.freewaycup.de/impressum">Impressum</a> -{" "}
          <a href="https://www.freewaycup.de/datenschutzerklaerung/">
            Datenschutz
          </a>
        </div>
      </AppShell>
    </>
  );
};
