import React from "react";
import { useGame } from "../../../../../hooks/useGame.ts";
import { useShootoutRounds } from "../../../../../hooks/useShootoutRounds.ts";
import { TeamName } from "../../../../../Components/TeamName.tsx";
import { IconBallFootball } from "@tabler/icons-react";

type ShootOutProps = {
  gameId?: string;
  team: "home" | "guest";
};

export const ShootOut: React.FC<ShootOutProps> = (props) => {
  const { gameId, team } = props;

  const game = useGame(gameId);
  const rounds = useShootoutRounds(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId;
  const guestTeamId = game.data?.actualTeams.guestTeamId;

  console.log(game.data);

  if (!game.isSuccess || !homeTeamId || !guestTeamId || !rounds) {
    return <></>;
  }

  const ballSize = Math.min(490 / rounds.length, 90);

  const failedShot = <IconBallFootball color="red" size={ballSize} />;
  const successfullShot = <IconBallFootball color="lime" size={ballSize} />;
  const unshotShot = <IconBallFootball color="grey" size={ballSize} />;

  const homeTeamShots = rounds.map((r) =>
    r.home ? (r.home.success ? successfullShot : failedShot) : unshotShot,
  );

  const guestTeamShots = rounds.map((r) =>
    r.guest ? (r.guest.success ? successfullShot : failedShot) : unshotShot,
  );

  return (
    <div
      style={{
        width: 720,
        height: 144,
        display: "flex",
        alignItems: "center",
        fontSize: 90,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 700,
        lineHeight: "144px",
      }}
    >
      <TeamName short teamId={team == "home" ? homeTeamId : guestTeamId} />
      <div
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          height: 144,
          display: "flex",
          alignItems: "center",
        }}
      >
        {team == "home" ? homeTeamShots : guestTeamShots}
      </div>
    </div>
  );
};
