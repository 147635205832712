import React, { FC, useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPlayer, getTopscorer } from "../../../openapi/api-client";
import { TeamName } from "../../../Components/TeamName";
import { PlayerNumber } from "../../../Components/PlayerNumber";
import { PlayerName } from "../../../Components/PlayerName";
import { Loader } from "@mantine/core";
import { CompetitionContext } from "../../../CompetitionContext.tsx";

const tdStyle: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 24,
  fontFamily: "Chivo",
};

export const Topscorer: FC = () => {
  const competitionId = useContext(CompetitionContext);

  const topscorerData = useQuery({
    queryKey: ["topscorer", { competitionId }],
    queryFn: async () => {
      const topscorer = await getTopscorer({ competitionId });
      return topscorer.data;
    },
  });

  if (!topscorerData.isSuccess) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 2px",
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: "#2a3485",
                color: "white",
              }}
            >
              <th
                style={{ ...tdStyle, textAlign: "left", fontWeight: 900 }}
                colSpan={2}
              >
                Topscorer
              </th>
              <th
                style={{
                  ...tdStyle,
                  textAlign: "center",
                  paddingTop: 20,
                  paddingBottom: 20,
                  fontWeight: 900,
                }}
              >
                Tore
              </th>
              <th style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}>
                Assists
              </th>
              <th style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}>
                Summe
              </th>
            </tr>
          </thead>
          <tbody>
            {topscorerData.data.topscorer.slice(0, 10).map((t, index) => (
              <PlayerRow
                key={t.playerId}
                playerId={t.playerId}
                position={index + 1}
                assists={t.assists}
                goals={t.goals}
              />
            ))}
            <tr>
              <td
                colSpan={5}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 24,
                  fontFamily: "Chivo",
                  fontWeight: 700,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 15,
                  }}
                >
                  <div style={{ paddingRight: 30 }}>
                    Der Topscorer wird präsentiert von
                  </div>
                  <img
                    src="https://app-assets.freewaycup.de/sponsorlogo/swierzy-logo-auf-schwarz.svg"
                    style={{ backgroundColor: "white" }}
                    height={75}
                    alt=""
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PlayerRow: FC<{
  playerId: string;
  position: number;
  assists: number;
  goals: number;
}> = (props) => {
  const { playerId, position, assists, goals } = props;

  const playerData = useQuery({
    queryKey: ["player", { playerId }],
    queryFn: async () => {
      const response = await getPlayer({ playerId });
      return response.data;
    },
  });

  if (!playerData.isSuccess) {
    return (
      <tr>
        <td colSpan={5}>
          <Loader />
        </td>
      </tr>
    );
  }

  return (
    <tr
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.95)",
      }}
    >
      <td style={{ ...tdStyle, textAlign: "right" }}>{position}.</td>
      <td style={{ ...tdStyle, width: 500, fontWeight: 700 }}>
        <PlayerName firstname playerId={playerId} /> (
        <TeamName short teamId={playerData.data.teamId} />,{" "}
        <PlayerNumber playerId={playerId} />)
      </td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{goals}</td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{assists}</td>
      <td style={{ ...tdStyle, textAlign: "center" }}>{goals + assists}</td>
    </tr>
  );
};
