import { getCurrentGame } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useCurrentGame = (competitionId?: string) => {
  return useQuery({
    queryKey: ["currentGame", { competitionId }],
    queryFn: async () => {
      if (!competitionId) {
        throw Error("competitionId not set");
      }

      const response = await getCurrentGame({ competitionId });
      return response.data;
    },
    enabled: !!competitionId,
  });
};
