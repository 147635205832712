import { FC, useContext } from "react";
import { useDebouncedCurrentGame } from "../../../../hooks/useDebouncedCurrentGame";
import { Grid, Skeleton, useMantineTheme } from "@mantine/core";
import { TeamName } from "../../../../Components/TeamName";
import { CompetitionContext } from "../../../../CompetitionContext.tsx";

export const CurrentGame: FC = () => {
  const competitionId = useContext(CompetitionContext);
  const theme = useMantineTheme();
  const currentGame = useDebouncedCurrentGame(competitionId, 5000);

  if (currentGame.isError) {
    return <></>;
  }

  if (
    !currentGame.isSuccess ||
    !currentGame.data.actualTeams.homeTeamId ||
    !currentGame.data.actualTeams.guestTeamId
  ) {
    return <Skeleton height={76} w="100%"></Skeleton>;
  }

  return (
    <Grid
      p={"xs"}
      style={{
        textAlign: "center",
        fontFamily: "Chivo",
        backgroundColor: theme.fn.primaryColor(),
        color: "white",
        fontSize: 34,
        lineHeight: "40px",
        margin: 0,
      }}
    >
      <Grid.Col span={3} style={{ textAlign: "left" }}>
        <TeamName short teamId={currentGame.data.actualTeams.homeTeamId} />
      </Grid.Col>
      <Grid.Col
        span={6}
        style={{
          textAlign: "center",
          fontFamily: "Chivo Mono",
          fontWeight: 700,
        }}
      >
        {currentGame.data.score.home}–{currentGame.data.score.guest}
      </Grid.Col>
      <Grid.Col span={3} style={{ textAlign: "right" }}>
        <TeamName short teamId={currentGame.data.actualTeams.guestTeamId} />
      </Grid.Col>
    </Grid>
  );
};
