import React, { FC } from "react";
import { TeamName } from "../../../../../Components/TeamName";
import { Grid } from "@mantine/core";
import { IconBallFootball } from "@tabler/icons-react";
import { useShootoutRounds } from "../../../../../hooks/useShootoutRounds";
import { useGame } from "../../../../../hooks/useGame";

const teamNameStyle: React.CSSProperties = {
  fontSize: 76,
  lineHeight: "76px",
  fontWeight: 700,
};

const failedShot = <IconBallFootball color="red" size={65} />;
const successfullShot = <IconBallFootball color="#00e64d" size={65} />;
const unshotShot = <IconBallFootball color="#888" size={65} />;

type DisplayShootoutProps = {
  gameId: string;
};

export const DisplayShootout: FC<DisplayShootoutProps> = (props) => {
  const { gameId } = props;

  const game = useGame(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId;
  const guestTeamId = game.data?.actualTeams.guestTeamId;

  const rounds = useShootoutRounds(gameId);

  if (!game.isSuccess || !homeTeamId || !guestTeamId || !rounds) {
    return <></>;
  }

  const homeTeamShots = rounds.map((r) =>
    r.home ? (r.home.success ? successfullShot : failedShot) : unshotShot,
  );

  const guestTeamShots = rounds.map((r) =>
    r.guest ? (r.guest.success ? successfullShot : failedShot) : unshotShot,
  );

  return (
    <>
      <div
        style={{
          padding: 15,
        }}
      >
        <Grid>
          <Grid.Col span={8}>
            <Grid>
              <Grid.Col span={3} style={teamNameStyle}>
                <TeamName short teamId={homeTeamId} />
              </Grid.Col>
              <Grid.Col span={9}>
                <div style={{ paddingTop: 5 }}>{homeTeamShots}</div>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={3} style={teamNameStyle}>
                <TeamName short teamId={guestTeamId} />
              </Grid.Col>
              <Grid.Col span={9}>
                <div style={{ paddingTop: 5 }}>{guestTeamShots}</div>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col
            span={4}
            style={{
              fontSize: 175,
              lineHeight: "155px",
              textAlign: "right",
              fontWeight: 700,
            }}
          >
            {
              game.data.shootOutRounds.filter((r) => r.home && r.home.success)
                .length
            }
            :
            {
              game.data.shootOutRounds.filter((r) => r.guest && r.guest.success)
                .length
            }
          </Grid.Col>
        </Grid>
      </div>
    </>
  );
};
