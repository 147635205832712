import { FC } from "react";
import { components } from "../openapi/generated-types";
import { useQuery } from "@tanstack/react-query";
import { getCompetition, getGame } from "../openapi/api-client";
import { TeamName } from "./TeamName";
import { TextSkeleton } from "./TextSkeleton";

type ResolvedTeamReferenceProps = {
  teamReference: components["schemas"]["TeamReference"];
  competitionId: string;
};

export const ResolvedTeamReference: FC<ResolvedTeamReferenceProps> = (
  props,
) => {
  const { teamReference, competitionId } = props;

  if (teamReference.winnerTeam) {
    return (
      <>
        Gewinner <GameName gameId={teamReference.winnerTeam} />
      </>
    );
  }

  if (teamReference.loserTeam) {
    return (
      <>
        Verlierer <GameName gameId={teamReference.loserTeam} />
      </>
    );
  }

  if (teamReference.teamId) {
    return <TeamName teamId={teamReference.teamId} />;
  }

  if (teamReference.groupId) {
    return (
      <TeamNameByGroupId
        groupId={teamReference.groupId}
        position={teamReference.position}
        competitionId={competitionId}
      />
    );
  }

  return <>Team kann nicht ermittelt werden</>;
};

const GameName: FC<{ gameId: string }> = (props) => {
  const { gameId } = props;
  const game = useQuery({
    queryKey: ["game", { gameId }],
    queryFn: async () => {
      const response = await getGame({ gameId });
      return response.data;
    },
  });

  if (!game.isSuccess) {
    return <TextSkeleton>Spiel 00</TextSkeleton>;
  }

  return <>{game.data.name}</>;
};

const TeamNameByGroupId: FC<{
  groupId: string;
  position: number;
  competitionId: string;
}> = (props) => {
  const { position, groupId, competitionId } = props;

  const competition = useQuery({
    queryKey: ["competition", { competitionId }],
    queryFn: async () => {
      const response = await getCompetition({
        competitionId,
      });
      return response.data;
    },
  });

  if (!competition.isSuccess) {
    return (
      <>
        <TextSkeleton>Gruppe</TextSkeleton>, Platz{" "}
        <TextSkeleton>00</TextSkeleton>
      </>
    );
  }

  const group = competition.data.groups.find((g) => g.id == groupId);
  if (!group) {
    throw new Error(
      `group ${groupId} not found in competition ${competition.data.name} (${competition.data.id})`,
    );
  }
  return (
    <>
      {group.name}, Platz {position}
    </>
  );
};
