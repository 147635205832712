import React, { CSSProperties } from "react";
import { Sponsor } from "./Sponsor.tsx";
import { useDebouncedCurrentGame } from "../../../../../hooks/useDebouncedCurrentGame.ts";
import { LedwallScore } from "./LedwallScore.tsx";
import { LedwallTime } from "./LedwallTime.tsx";
import { Text } from "./Text.tsx";
import { useFullscreen } from "@mantine/hooks";
import { ShootOut } from "./ShootOut.tsx";
import { ActivityItem } from "./ActivityItem.tsx";

type DisplayProps = {
  competitionId?: string;
  text?: string;
  activityItem?: ActivityItem;
  disabled?: boolean;
};

const wholeLedwallBoxStyle: CSSProperties = {
  zIndex: 1000,
  color: "white",
  fontFamily: "Chivo Mono",
  overflow: "hidden",
};

const singleLedwallBoxStyle: CSSProperties = {
  backgroundColor: "black",
  width: 1920,
  height: 144,
  left: 0,
  display: "flex",
};

export const Display: React.FC<DisplayProps> = (props) => {
  const { competitionId, text, disabled, activityItem } = props;

  const { fullscreen } = useFullscreen();
  const currentGame = useDebouncedCurrentGame(competitionId, 10 * 1000);

  return (
    <div
      style={{
        ...wholeLedwallBoxStyle,
        position: fullscreen ? "absolute" : undefined,
        left: fullscreen ? 0 : undefined,
        top: fullscreen ? 0 : undefined,
      }}
    >
      <div
        style={{
          ...singleLedwallBoxStyle,
        }}
      >
        {!disabled && (
          <>
            {text && <Text text={text} />}
            {activityItem && !text && (
              <ActivityItem activityItem={activityItem} />
            )}
            {!text && !activityItem && currentGame.isSuccess && (
              <>
                <Sponsor gameId={currentGame.data.id} team="home" />
                {currentGame.data.shootOutStarted ? (
                  <ShootOut team="home" gameId={currentGame.data.id} />
                ) : (
                  <LedwallTime gameId={currentGame.data.id} />
                )}
              </>
            )}
          </>
        )}
      </div>
      <div
        style={{
          ...singleLedwallBoxStyle,
          marginTop: fullscreen ? undefined : 1,
        }}
      >
        {!disabled && (
          <>
            {text && <Text text={text} />}
            {activityItem && !text && (
              <ActivityItem activityItem={activityItem} />
            )}
            {!text && !activityItem && currentGame.isSuccess && (
              <>
                {currentGame.data.shootOutStarted ? (
                  <ShootOut team="guest" gameId={currentGame.data.id} />
                ) : (
                  <LedwallScore gameId={currentGame.data.id} />
                )}
                <Sponsor gameId={currentGame.data.id} team="guest" />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
