import { FC } from "react";
import { AspectRatio } from "@mantine/core";
import { useNetwork } from "@mantine/hooks";

export const Stream: FC = () => {
  const network = useNetwork();

  const autostart = network.type != "cellular" && !network.saveData;

  console.log(network);

  return (
    <AspectRatio ratio={16 / 9}>
      <iframe
        title="Livestream"
        style={{ border: "none", width: "100%", height: "100%" }}
        allowFullScreen
        src={`https://start.video-stream-hosting.de/player.html?serverapp=freewaycup-live&streamname=livestream.smil&bgimage=&playsinline=1&autostart=${
          autostart ? "1&mute=1" : "0"
        }`}
      />
    </AspectRatio>
  );
};
