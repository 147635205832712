import { FC } from "react";
import { Box, Text } from "@mantine/core";
import { useDebouncedCurrentGame } from "../../../../../hooks/useDebouncedCurrentGame";
import { DisplayGame } from "./DisplayGame";
import { DisplayShootout } from "./DisplayShootout";
import { useFullscreen } from "@mantine/hooks";

type DisplayProps = {
  competitionId?: string;
  text?: string;
  disabled?: boolean;
};

const DisplayText: FC<{ text: string }> = (props) => (
  <Text
    size={50}
    align="center"
    sx={() => ({
      lineHeight: "54px",
      fontFamily: "Chivo Mono",
      padding: 15,
      height: "100%",
    })}
  >
    {props.text}
  </Text>
);

export const Display: FC<DisplayProps> = (props) => {
  const { competitionId, text, disabled } = props;
  const { fullscreen } = useFullscreen();

  const currentGameData = useDebouncedCurrentGame(competitionId, 10 * 1000);

  return (
    <Box
      sx={() => ({
        backgroundColor: "black",
        height: 190,
        width: 1024,
        color: "white",
        alignContent: "center",
        fontFamily: "Chivo Mono",
        position: fullscreen ? "absolute" : undefined,
        left: fullscreen ? 0 : undefined,
        top: fullscreen ? 0 : undefined,
        zIndex: 1000,
      })}
    >
      {!disabled && (
        <>
          {text && <DisplayText text={text} />}
          {!text && currentGameData.isSuccess && (
            <>
              {currentGameData.data.shootOutStarted ? (
                <DisplayShootout gameId={currentGameData.data.id} />
              ) : (
                <DisplayGame gameId={currentGameData.data.id} />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
