import { socket } from "../socket";
import { useEffect, useState } from "react";
import { getGame } from "../openapi/api-client";
import { useQuery } from "@tanstack/react-query";

export const useGameTime = (gameId?: string): number => {
  const [time, setTime] = useState<number>(0);

  const game = useQuery({
    queryKey: ["game", { gameId }],
    queryFn: async () => {
      if (!gameId) {
        throw new Error("game id not given ");
      }
      const response = await getGame({ gameId });
      return response.data;
    },
    enabled: !!gameId,
  });

  useEffect(() => {
    socket.on("tick", (event) => {
      console.log(`tick - ${event.gameId} - ${event.time}`);
      if (event.gameId == gameId) {
        setTime(event.time);
      }
    });

    return () => {
      socket.removeListener("tick");
    };
  }, [gameId]);

  useEffect(() => {
    if (!game.isSuccess) {
      return;
    }

    if (!game.data.started) {
      setTime(game.data.duration);
    }

    if (game.data.clockPausedAt) {
      setTime(game.data.clockPausedAt);
    }
  }, [game.data, game.isSuccess]);

  return time;
};
