import { listGamesByTeamAndCompetition } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const useTeamGames = (competitionId?: string, teamId?: string) => {
  return useQuery({
    queryKey: ["games", { teamId, competitionId }],
    queryFn: async () => {
      if (!teamId) {
        throw new Error("teamId not set");
      }
      if (!competitionId) {
        throw new Error("competitionId not set");
      }
      const response = await listGamesByTeamAndCompetition({
        teamId,
        competitionId,
      });
      return response.data;
    },
    enabled: !!teamId && !!competitionId,
  });
};
