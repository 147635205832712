import React, { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { getLeaderboard } from "../../../openapi/api-client";
import { TeamName } from "../../../Components/TeamName";
import { useRequiredParams } from "../../../hooks/useRequiredParams";

const tdStyle: React.CSSProperties = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: 24,
  fontFamily: "Chivo",
};

export const Leaderboard: FC = () => {
  const { competitionId, groupId } = useRequiredParams([
    "competitionId",
    "groupId",
  ]);

  const leaderboardData = useQuery({
    queryKey: ["leaderboard", { competitionId, groupId }],
    queryFn: async () => {
      const leaderboard = await getLeaderboard({ competitionId, groupId });
      return leaderboard.data;
    },
  });

  if (!leaderboardData.isSuccess) {
    return <></>;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 2px",
          }}
        >
          <thead>
            <tr
              style={{
                backgroundColor: "#2a3485",
                color: "white",
              }}
            >
              <th
                style={{ ...tdStyle, textAlign: "left", fontWeight: 900 }}
                colSpan={2}
              >
                Vorrunden-Tabelle
              </th>
              <th
                style={{
                  ...tdStyle,
                  textAlign: "center",
                  paddingTop: 20,
                  paddingBottom: 20,
                  fontWeight: 900,
                }}
              >
                Tor-Diff.
              </th>
              <th style={{ ...tdStyle, textAlign: "center", fontWeight: 900 }}>
                Punkte
              </th>
            </tr>
          </thead>
          <tbody>
            {leaderboardData.data.leaderboard.map((l, index) => {
              return (
                <tr
                  key={l.teamId}
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.95)",
                  }}
                >
                  <td style={{ ...tdStyle, textAlign: "right" }}>
                    {index + 1}.
                  </td>
                  <td style={{ ...tdStyle, width: 500, fontWeight: 700 }}>
                    <TeamName teamId={l.teamId} />
                  </td>
                  <td style={{ ...tdStyle, textAlign: "center" }}>
                    {l.goalDifference}
                  </td>
                  <td style={{ ...tdStyle, textAlign: "center" }}>
                    {l.points}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
