import { FC } from "react";
import { Time } from "../../../../Components/Time";
import { components } from "../../../../openapi/generated-types";
import { PlayerNumber } from "../../../../Components/PlayerNumber";

type TimePenaltyCountdownProps = {
  time: number;
  timePenalty: components["schemas"]["TimePenalty"];
  showEndTime?: boolean;
};

export const TimePenaltyCountdown: FC<TimePenaltyCountdownProps> = (props) => {
  const { time, timePenalty, showEndTime } = props;

  const endTime = timePenalty.time - timePenalty.duration;
  const timeLeft = time + timePenalty.duration - timePenalty.time;

  const playerJsx = (
    <span style={{ fontWeight: 700 }}>
      <PlayerNumber padding playerId={timePenalty.playerId} />
    </span>
  );
  const timeLeftJsx = <Time time={timeLeft} />;
  const endTimeJsx = showEndTime ? (
    <>({endTime >= 0 ? <Time time={endTime} /> : <>--</>})</>
  ) : (
    <></>
  );

  return (
    <>
      {playerJsx} – {timeLeftJsx} {endTimeJsx}
    </>
  );
};
