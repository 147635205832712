import { FC } from "react";
import { Page } from "../../../Components/Page";
import { Button, Group, Loader, Table } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { components } from "../../../openapi/generated-types";
import { DateTime } from "luxon";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";
import { setupFWC2025 } from "../../../fwc-2025.ts";

type TableRowProps = {
  competition: components["schemas"]["Competition"];
};

const TableRow: FC<TableRowProps> = (props) => {
  const { competition } = props;

  return (
    <tr>
      <td>
        <Link to={competition.id}>{competition.name}</Link>
      </td>
      <td>{competition.location}</td>
      <td>{DateTime.fromISO(competition.start).toFormat("dd.MM.yyyy")}</td>
      <td>{DateTime.fromISO(competition.end).toFormat("dd.MM.yyyy")}</td>
      <td>{competition.teamIds.length}</td>
    </tr>
  );
};

export const CompetitionOverview: FC = () => {
  const navigate = useNavigate();
  const competitionData = useCompetitionList();

  if (!competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title={"Turniere"}>
      <Group>
        <Button mb="md" onClick={() => navigate("new")}>
          Neues Turnier
        </Button>
        <Button mb="md" onClick={() => setupFWC2025()}>
          FWC 2025 generieren
        </Button>
      </Group>
      <Table mt="lg">
        <thead>
          <tr>
            <th>Turnier</th>
            <th>Ort</th>
            <th>Start</th>
            <th>Ende</th>
            <th>Mannschaften</th>
          </tr>
        </thead>
        <tbody>
          {competitionData.data.competitions.map((c) => (
            <TableRow key={c.id} competition={c} />
          ))}
        </tbody>
      </Table>
    </Page>
  );
};
