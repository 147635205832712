import { useDebouncedState } from "@mantine/hooks";
import { useEffect } from "react";
import { useGame } from "./useGame.ts";
import { useCurrentGame } from "./useCurrentGame.ts";

export const useDebouncedCurrentGame = (
  competitionId?: string,
  debounceInMs?: number,
) => {
  const [debouncedCurrentGameId, setDebouncedCurrentGameId] = useDebouncedState(
    "",
    debounceInMs ?? 30 * 1000,
    { leading: true },
  );

  const currentGame = useCurrentGame(competitionId);

  useEffect(() => {
    if (!currentGame.isSuccess) {
      return;
    }

    setDebouncedCurrentGameId(currentGame.data.id);
  }, [currentGame.data]);

  return useGame(debouncedCurrentGameId);
};
