import { FC } from "react";
import { usePlayer } from "../hooks/usePlayer";
import { TextSkeleton } from "./TextSkeleton";

type PlayerNumberProps = {
  playerId: string;
  padding?: boolean;
};

export const PlayerNumber: FC<PlayerNumberProps> = (props) => {
  const { playerId, padding } = props;
  const player = usePlayer(playerId);

  if (!player.isSuccess) {
    return <TextSkeleton>00</TextSkeleton>;
  }

  return (
    <>
      {padding && player.data.number < 10 && <>&nbsp;</>}
      {player.data.number}
    </>
  );
};
