import { Page } from "../../../Components/Page";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import { createCompetition } from "../../../openapi/api-client";
import { v4 as uuid } from "uuid";
import { Button, Group, Stack, TextInput } from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import { DatePickerInput } from "@mantine/dates";

export const CompetitionNew: FC = () => {
  const navigate = useNavigate();

  const form = useForm<{
    name: string;
    dateRange: [Date, Date];
    location: string;
  }>({
    initialValues: {
      name: "",
      location: "",
      dateRange: [new Date(), new Date()],
    },
  });

  const createCompetitionMutation = useMutation({
    mutationFn: createCompetition,
  });

  const onSubmit = form.onSubmit(async (values) => {
    console.log(values);

    await createCompetitionMutation.mutateAsync({
      id: uuid(),
      name: values.name,
      location: values.location,
      start: values.dateRange[0].toISOString(),
      end: values.dateRange[1].toISOString(),
    });

    navigate(`../competitions`);
  });

  return (
    <Page title={`Neues Turnier`}>
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <TextInput
            placeholder="FreewayCup 2022"
            label="Name"
            required
            {...form.getInputProps("name")}
          />

          <TextInput
            placeholder="Merkur Arena (Lübbecke)"
            label="Ort"
            required
            {...form.getInputProps("location")}
          />

          <DatePickerInput
            type="range"
            label="Zeitraum"
            placeholder="Zeitraum auswählen"
            required
            clearable={false}
            {...form.getInputProps("dateRange")}
          />

          <Group mt="md">
            <Button type="submit">Turnier anlegen</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
