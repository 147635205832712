import React, { FC } from "react";
import { Title } from "@mantine/core";

type PageProps = {
  title?: React.ReactNode;
  children?: React.ReactNode;
};

export const Page: FC<PageProps> = (props) => {
  const { title, children } = props;

  return (
    <>
      {title && <Title mb="md">{title}</Title>}
      {children}
    </>
  );
};
