import { FC, useState } from "react";
import { Button, Grid, Loader, Select } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { getCurrentGame } from "../../../openapi/api-client";
import { Page } from "../../../Components/Page";
import { RegieGameDetails } from "./Components/RegieGameDetails";
import { useShowCommentatorNotification } from "../../../hooks/useCommentatorNotification";
import {
  IconHeadset,
  IconHeadsetOff,
  IconQuestionMark,
} from "@tabler/icons-react";
import { CompetitionCommand, sendCommand } from "../../../commandTypes.ts";
import { useCommand } from "../../../hooks/useCommand.ts";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";
import { useCurrentGame } from "../../../hooks/useCurrentGame.ts";

export const Regie: FC = () => {
  const [competitionId, setCompetitionId] = useState<string>(
    import.meta.env.VITE_APP_COMPETITION_ID,
  );
  useCommand<CompetitionCommand>("competition", (c) => {
    setCompetitionId(c.competitionId);
  });

  const competitionData = useCompetitionList();
  const showCommentatorNotification = useShowCommentatorNotification();

  const currentGameData = useCurrentGame(competitionId);

  const upcomingGameData = useQuery({
    queryKey: ["currentGame", { competitionId, skip: 1 }],
    queryFn: async () => {
      if (!competitionId) {
        throw Error("competitionId not set");
      }

      const response = await getCurrentGame({ competitionId, skip: 1 });
      return response.data;
    },
    enabled: !!competitionId,
  });

  if (!competitionData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page title="Regie">
      <Select
        my="md"
        label="Turnier"
        value={competitionId}
        onChange={(v) => {
          if (!v) {
            return;
          }
          setCompetitionId(v);
        }}
        data={competitionData.data.competitions.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
      />

      <Button
        size="xl"
        onClick={() =>
          showCommentatorNotification
            ? sendCommand({ command: "commentator.off" })
            : sendCommand({ command: "commentator.on" })
        }
        color={
          showCommentatorNotification
            ? "red"
            : showCommentatorNotification == undefined
              ? "yellow"
              : "green"
        }
        leftIcon={
          showCommentatorNotification ? (
            <IconHeadsetOff />
          ) : showCommentatorNotification == undefined ? (
            <IconQuestionMark />
          ) : (
            <IconHeadset />
          )
        }
      >
        {showCommentatorNotification
          ? "Meldung entfernen"
          : showCommentatorNotification == undefined
            ? "Status unbekannt"
            : "Meldung anzeigen"}
      </Button>

      <Grid mt="lg">
        <Grid.Col xs={6}>
          {currentGameData.isSuccess ? (
            <RegieGameDetails gameId={currentGameData.data.id} />
          ) : (
            <Loader />
          )}
        </Grid.Col>
        <Grid.Col xs={6}>
          {upcomingGameData.isSuccess ? (
            <RegieGameDetails gameId={upcomingGameData.data.id} />
          ) : (
            <Loader />
          )}
        </Grid.Col>
      </Grid>
    </Page>
  );
};
