import React, { useState } from "react";
import { GameDetail } from "./Detail.tsx";
import { useCommand } from "../../../hooks/useCommand.ts";
import { CompetitionCommand } from "../../../commandTypes.ts";
import { useDebouncedCurrentGame } from "../../../hooks/useDebouncedCurrentGame.ts";
import { Loader, Select } from "@mantine/core";
import { useCompetitionList } from "../../../hooks/useCompetitionList.ts";

export const Timekeeper: React.FC = () => {
  const [competitionId, setCompetitionId] = useState<string>(
    import.meta.env.VITE_APP_COMPETITION_ID,
  );

  const competitionList = useCompetitionList();

  useCommand<CompetitionCommand>("competition", (c) => {
    setCompetitionId(c.competitionId);
  });

  const currentGame = useDebouncedCurrentGame(competitionId, 5 * 1000);

  if (!competitionList.isSuccess || !currentGame.isSuccess) {
    return <Loader />;
  }

  return (
    <>
      <Select
        mb="xl"
        label="Turnier"
        value={competitionId}
        onChange={(value) => {
          if (!value) {
            return;
          }

          setCompetitionId(value);
        }}
        data={competitionList.data.competitions.map((c) => ({
          value: c.id,
          label: c.name,
        }))}
      />
      <GameDetail gameId={currentGame.data.id} />
    </>
  );
};
