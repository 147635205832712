import { listPlayersByTeam } from "../openapi/api-client.ts";
import { useQuery } from "@tanstack/react-query";

export const usePlayers = (teamId?: string) => {
  return useQuery({
    queryKey: ["players", { teamId }],
    queryFn: async () => {
      if (!teamId) {
        throw new Error("teamId not set");
      }

      const response = await listPlayersByTeam({ teamId });
      return response.data.players;
    },
    enabled: !!teamId,
  });
};
