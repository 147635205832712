import React from "react";
import { TeamName } from "../../../../../Components/TeamName.tsx";
import { useGame } from "../../../../../hooks/useGame.ts";

type LedwallScoreProps = { gameId: string };

export const LedwallScore: React.FC<LedwallScoreProps> = (props) => {
  const { gameId } = props;

  const game = useGame(gameId);

  const homeTeamId = game.data?.actualTeams.homeTeamId || "";
  const guestTeamId = game.data?.actualTeams.guestTeamId || "";
  const score = `${game.data?.score.home}:${game.data?.score.guest}`;

  return (
    <div
      style={{
        width: 720,
        height: 144,
        fontSize: 90,
        paddingLeft: 25,
        paddingRight: 25,
        fontWeight: 700,
        lineHeight: "144px",
        display: "flex",
        justifyContent: "space-between",
        color: "white",
      }}
    >
      {game.isSuccess && (
        <>
          <div>
            <TeamName short teamId={homeTeamId} />
          </div>
          <div>{score}</div>
          <div>
            <TeamName short teamId={guestTeamId} />
          </div>
        </>
      )}
    </div>
  );
};
