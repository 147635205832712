import React from "react";

export type ActivityItem = {
  id: string;
  label: string;
  src: string;
};

type ActivityItemProps = {
  activityItem: ActivityItem;
};

export const ActivityItem: React.FC<ActivityItemProps> = (props) => {
  const { activityItem } = props;
  return <img src={activityItem.src} alt={activityItem.label} />;
};
