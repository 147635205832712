import { Fetcher } from "openapi-typescript-fetch";
import { paths } from "./generated-types";

const fetcher = Fetcher.for<paths>();

// global configuration
fetcher.configure({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URI,
});

// create fetch operations
export const listGamesByCompetition = fetcher
  .path("/v1/game.read/list-by-competition")
  .method("get")
  .create();
export const listGamesByTeamAndCompetition = fetcher
  .path("/v1/game.read/list-by-team-and-competition")
  .method("get")
  .create();
export const planGame = fetcher
  .path("/v1/game.write/plan")
  .method("post")
  .create();
export const postponeGame = fetcher
  .path("/v1/game.write/postpone")
  .method("post")
  .create();
export const gameChangeDateTime = fetcher
  .path("/v1/game.write/change-datetime")
  .method("post")
  .create();
export const gameStart = fetcher
  .path("/v1/game.write/start")
  .method("post")
  .create();
export const gamePauseClock = fetcher
  .path("/v1/game.write/pause-clock")
  .method("post")
  .create();
export const gameResumeClock = fetcher
  .path("/v1/game.write/resume-clock")
  .method("post")
  .create();
export const gameScoreGoal = fetcher
  .path("/v1/game.write/score-goal")
  .method("post")
  .create();
export const gameRevokeGoal = fetcher
  .path("/v1/game.write/revoke-goal")
  .method("post")
  .create();
export const gameSetActualTeams = fetcher
  .path("/v1/game.write/set-actual-teams")
  .method("post")
  .create();
export const gameFreezeRegularTime = fetcher
  .path("/v1/game.write/freeze-regular-time")
  .method("post")
  .create();
export const gameTrackShootOutShot = fetcher
  .path("/v1/game.write/track-shoot-out-shot")
  .method("post")
  .create();
export const gameGiveTimePenalty = fetcher
  .path("/v1/game.write/give-timepenalty")
  .method("post")
  .create();
export const gameChangeDuration = fetcher
  .path("/v1/game.write/change-duration")
  .method("post")
  .create();
export const gameChangeShootOutShotsNumber = fetcher
  .path("/v1/game.write/change-shoot-out-shots-number")
  .method("post")
  .create();
export const getGame = fetcher.path("/v1/game.read/get").method("get").create();
export const createTeam = fetcher
  .path("/v1/team.write/create")
  .method("post")
  .create();
export const listTeams = fetcher
  .path("/v1/team.read/list")
  .method("get")
  .create();
export const getTeam = fetcher.path("/v1/team.read/get").method("get").create();
export const createPlayer = fetcher
  .path("/v1/player.write/create")
  .method("post")
  .create();
export const playerChangeTeam = fetcher
  .path("/v1/player.write/change-team")
  .method("post")
  .create();
export const listPlayers = fetcher
  .path("/v1/player.read/list")
  .method("get")
  .create();
export const listPlayersByTeam = fetcher
  .path("/v1/player.read/list-by-team")
  .method("get")
  .create();
export const getPlayer = fetcher
  .path("/v1/player.read/get")
  .method("get")
  .create();
export const createCompetition = fetcher
  .path("/v1/competition.write/create")
  .method("post")
  .create();
export const enrollTeam = fetcher
  .path("/v1/competition.write/enroll-team")
  .method("post")
  .create();
export const createCompetitionGroup = fetcher
  .path("/v1/competition.write/create-group")
  .method("post")
  .create();
export const createCompetitionMatchday = fetcher
  .path("/v1/competition.write/create-matchday")
  .method("post")
  .create();
export const addTeamToGroup = fetcher
  .path("/v1/competition.write/add-team-to-group")
  .method("post")
  .create();
export const listCompetitions = fetcher
  .path("/v1/competition.read/list")
  .method("get")
  .create();
export const getCompetition = fetcher
  .path("/v1/competition.read/get")
  .method("get")
  .create();
export const getLeaderboard = fetcher
  .path("/v1/competition.read/leaderboard")
  .method("get")
  .create();
export const getTopscorer = fetcher
  .path("/v1/competition.read/topscorer")
  .method("get")
  .create();
export const getCurrentGame = fetcher
  .path("/v1/game.read/current")
  .method("get")
  .create();
export const livestreamRegieReady = fetcher
  .path("/v1/game.write/livestream-regie-ready")
  .method("post")
  .create();
export const gameSetLinks = fetcher
  .path("/v1/game.write/set-links")
  .method("post")
  .create();
export const troetHorn = fetcher.path("/v1/horn").method("post").create();
export const startCountdown = fetcher
  .path("/v1/countdown")
  .method("post")
  .create();
export const sendCommand = fetcher
  .path("/v1/command/send")
  .method("post")
  .create();
