import React from "react";
import { useNetwork } from "@mantine/hooks";
import { Alert } from "@mantine/core";
import { IconCloudOff } from "@tabler/icons-react";

export const OfflineIndicator: React.FC = () => {
  const network = useNetwork();

  return network.online ? (
    <></>
  ) : (
    <Alert icon={<IconCloudOff />} variant="filled" color="red">
      Du bist offline - wir können keine Echtzeitdaten laden.
    </Alert>
  );
};
