import React from "react";

type TextProps = {
  text: string;
};

export const Text: React.FC<TextProps> = (props) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
        fontSize: 50,
        lineHeight: 1.2,
        display: "flex",
        alignItems: "center",
        padding: 25,
        overflow: "hidden",
      }}
    >
      <div style={{ width: "100%" }}>{props.text}</div>
    </div>
  );
};
