import { createBrowserRouter } from "react-router-dom";
import Control from "./Pages/Control/Control";
import { CompetitionOverview } from "./Pages/Control/Competition/Overview";
import { PlayerOverview } from "./Pages/Control/Player/Overview";
import { TeamOverview } from "./Pages/Control/Team/Overview";
import { CompetitionDetail } from "./Pages/Control/Competition/Detail";
import { GameNew } from "./Pages/Control/Game/New";
import { TeamNew } from "./Pages/Control/Team/New";
import { PlayerNew } from "./Pages/Control/Player/New";
import { CompetitionNew } from "./Pages/Control/Competition/New";
import { EnrollTeams } from "./Pages/Control/Competition/EnrollTeams";
import { ChangeDateTime } from "./Pages/Control/Game/ChangeDateTime";
import { ChangeTeam } from "./Pages/Control/Player/ChangeTeam";
import { SetActualTeams } from "./Pages/Control/Game/SetActualTeams";
import { Regie } from "./Pages/Control/Regie/Regie";
import { Ledwall } from "./Pages/Control/Ledwall/Ledwall";
import { Streamelements } from "./Pages/Stremelements/Streamelements";
import { Score } from "./Pages/Stremelements/Elements/Score";
import { Shootout } from "./Pages/Stremelements/Elements/Shootout";
import { Commentator } from "./Pages/Control/Commentator/Commentator";
import { NextMatch } from "./Pages/Stremelements/Elements/NextMatch";
import { Leaderboard as StreamElementLeaderboard } from "./Pages/Stremelements/Elements/Leaderboard";
import { Topscorer as StreamElementTopscorer } from "./Pages/Stremelements/Elements/Topscorer";
import { App } from "./Pages/App/App";
import { Games } from "./Pages/App/Components/Pages/Games";
import { Teams } from "./Pages/App/Components/Pages/Teams";
import { Topscorer } from "./Pages/App/Components/Pages/Topscorer";
import { Leaderboard } from "./Pages/App/Components/Pages/Leaderboard";
import { Team } from "./Pages/App/Components/Pages/Team";
import { TopscorerFullscreen } from "./Pages/Stremelements/Elements/TopscorerFullscreen";
import { Countdown } from "./Pages/Stremelements/Elements/Countdown";
import { ChangeDuration } from "./Pages/Control/Game/ChangeDuration.tsx";
import { ChangeShootOutShotsNumber } from "./Pages/Control/Game/ChangeShootOutShotsNumber.tsx";
import { CompetitionContext } from "./CompetitionContext.tsx";
import { Command } from "./Pages/Control/Command/Command.tsx";
import { Timekeeper } from "./Pages/Control/Game/Timekeeper.tsx";
import { RouteWrapper } from "./Pages/Control/Game/RouteWrapper.tsx";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: (
      <CompetitionContext.Provider
        value={import.meta.env.VITE_APP_COMPETITION_ID}
      >
        <App />
      </CompetitionContext.Provider>
    ),
    errorElement: <p>Error</p>,
    children: [
      {
        path: "games",
        element: <Games />,
      },
      {
        path: "teams",
        element: <Teams />,
      },
      {
        path: "teams/:teamId",
        element: <Team />,
      },
      {
        path: "leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "topscorer",
        element: <Topscorer />,
      },
    ],
  },
  {
    path: "streamelements",
    element: <Streamelements />,
    errorElement: <p>Error</p>,
    children: [
      {
        path: "score",
        element: <Score />,
      },
      {
        path: "shootout",
        element: <Shootout />,
      },
      {
        path: "next-match",
        element: <NextMatch />,
      },
      {
        path: ":competitionId/:groupId/leaderboard",
        element: <StreamElementLeaderboard />,
      },
      {
        path: "topscorer",
        element: <StreamElementTopscorer />,
      },
      {
        path: "topscorer-fullscreen",
        element: <TopscorerFullscreen />,
      },
      {
        path: "countdown",
        element: <Countdown />,
      },
    ],
  },
  {
    path: "control",
    element: <Control />,
    errorElement: <p>Error</p>,
    children: [
      {
        path: "competitions",
        element: <CompetitionOverview />,
      },
      {
        path: "competitions/new",
        element: <CompetitionNew />,
      },
      {
        path: "competitions/:competitionId",
        element: <CompetitionDetail />,
      },
      {
        path: "competitions/:competitionId/games/new",
        element: <GameNew />,
      },
      {
        path: "competitions/:competitionId/games/:gameId",
        element: <RouteWrapper />,
      },
      {
        path: "competitions/:competitionId/games/:gameId/change-datetime",
        element: <ChangeDateTime />,
      },
      {
        path: "competitions/:competitionId/games/:gameId/change-duration",
        element: <ChangeDuration />,
      },
      {
        path: "competitions/:competitionId/games/:gameId/change-shoot-out-shots-number",
        element: <ChangeShootOutShotsNumber />,
      },
      {
        path: "competitions/:competitionId/games/:gameId/set-actual-teams",
        element: <SetActualTeams />,
      },
      {
        path: "competitions/:competitionId/teams/enroll",
        element: <EnrollTeams />,
      },
      {
        path: "timekeeper",
        element: <Timekeeper />,
      },
      {
        path: "players",
        element: <PlayerOverview />,
      },
      {
        path: "players/new",
        element: <PlayerNew />,
      },
      {
        path: "players/:playerId/change-team",
        element: <ChangeTeam />,
      },
      {
        path: "teams",
        element: <TeamOverview />,
      },
      {
        path: "teams/new",
        element: <TeamNew />,
      },
      {
        path: "regie",
        element: <Regie />,
      },
      {
        path: "ledwall",
        element: <Ledwall />,
      },
      {
        path: "commentator",
        element: <Commentator />,
      },
      {
        path: "command",
        element: <Command />,
      },
    ],
  },
]);
