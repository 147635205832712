import { Page } from "../../../Components/Page";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import {
  getPlayer,
  listTeams,
  playerChangeTeam,
} from "../../../openapi/api-client";
import {
  Button,
  Group,
  Loader,
  NumberInput,
  Select,
  Stack,
} from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useRequiredParams } from "../../../hooks/useRequiredParams";

export const ChangeTeam: FC = () => {
  const navigate = useNavigate();
  const { playerId } = useRequiredParams(["playerId"]);

  const teamData = useQuery({
    queryKey: ["teams"],
    queryFn: async () => {
      const response = await listTeams({});
      return response.data;
    },
  });

  const playerData = useQuery({
    queryKey: [
      "player",
      {
        playerId,
      },
    ],
    queryFn: async () => {
      const response = await getPlayer({ playerId });
      return response.data;
    },
  });

  const form = useForm<{
    teamId: string;
    number: number;
  }>({
    initialValues: {
      teamId: "",
      number: 1,
    },
  });

  useEffect(() => {
    if (!playerData.isSuccess) {
      return;
    }

    form.setFieldValue("teamId", playerData.data.teamId);
    form.setFieldValue("number", playerData.data.number);
  }, [playerData.data, playerData.isSuccess]);

  const changeTeamMutation = useMutation({ mutationFn: playerChangeTeam });

  const onSubmit = form.onSubmit(async (values) => {
    if (!playerData.isSuccess) {
      throw new Error("player not loaded");
    }

    console.log(values);

    await changeTeamMutation.mutateAsync({
      id: playerData.data.id,
      teamId: values.teamId,
      number: values.number,
    });

    navigate(`../..`, { relative: "path" });
  });

  if (!teamData.isSuccess || !playerData.isSuccess) {
    return <Loader />;
  }

  return (
    <Page
      title={`Mannschaft von ${playerData.data.firstName} ${playerData.data.lastName} ändern`}
    >
      <form onSubmit={onSubmit}>
        <Stack align="flex-start" justify="flex-start">
          <Group>
            <Select
              label="Mannschaft"
              placeholder="Mannschaft auswählen"
              searchable
              required
              nothingFound="keine passende Mannschaft gefunden"
              data={teamData.data.teams.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              {...form.getInputProps("teamId")}
            />
            <NumberInput
              placeholder="0"
              label="Trikotnummer"
              required
              min={1}
              {...form.getInputProps("number")}
            />
          </Group>

          <Group mt="md">
            <Button type="submit">Mannschaft ändern</Button>
          </Group>
        </Stack>
      </form>
    </Page>
  );
};
